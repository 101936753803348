import SampleModalView from 'components/Modals/SampleModalView';
import { Card, CardBody, CardHeader, Col, Input, Row } from 'reactstrap';
import { paydComissionSellerService } from 'services/Comission/SellerComissionService';
import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';

export const CheckoutPaymentSellerComponent = ({
  openModal = false,
  setOpenModal = () => {},
  sellerPayment = {
    name: null,
    contactPhone: null,
    pix: null,
    valueBasisCommission: null,
    valueComissionSeller: null,
    indicationId: null,
    qtdContract: null,
  },
  setSellerPayment = () => {},
  filter = {},
  idxList = null,
  list = [],
  setList = () => {},
}) => {
  const handleSubmit = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que desejá pagar este(a) vendedor / indicação?',
      showCancelButton: true,
      cancelButtonText: 'Não, sair',
      confirmButtonText: 'Sim, pagar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Salvando',
        text: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      paydComissionSellerService({
        ...filter,
        ...sellerPayment,
      })
        .then(() => {
          Swal.close();

          const seller = {
            ...sellerPayment,
            valueComissionSellerPaid:
              sellerPayment.valueComissionSellerPaid +
              sellerPayment.valueComissionSeller,
            valueComissionSeller: 0,
          };

          setSellerPayment(seller);

          if (idxList !== null) {
            const aux = list;
            aux[idxList] = seller;
            setList(aux);
          }

          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Vendedor / Indicação pago(a) com sucesso!',
          }).then(() => {
            setOpenModal(false);
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  if (!sellerPayment?.indicationId || sellerPayment?.indicationId <= 0)
    return <></>;

  return (
    <SampleModalView
      labelModal="Fechamento Repasse Vendedor"
      icon="fa fa-search"
      isOpen={openModal}
      setIsOpen={setOpenModal}
      showBtnSubmit={true}
      btnSubmitLabel={`Realizar fechamento`}
      btnSubmitAction={handleSubmit}
      size="xl"
    >
      <Card className="shadow">
        <CardHeader>
          <h3 className="m-0">
            <i className="fas fa-user-tie" /> Vendedor / Indicação
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <p className="m-0">Nome:</p>
              <h4 className="m-0">{sellerPayment?.name}</h4>
            </Col>
            <Col md="3">
              <p className="m-0">Contato:</p>
              <h4 className="m-0">{sellerPayment?.contactPhone}</h4>
            </Col>
            <Col md="5">
              <p className="m-0">Chave PIX:</p>
              <h4 className="m-0">
                <Input value={sellerPayment?.pix} />
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="shadow mt-4">
        <CardHeader>
          <h3 className="m-0">
            <i className="fas fa-wallet" /> Repasse
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="4">
              <p className="m-0">Vlr. Produção:</p>
              <h4 className="m-0">
                R$ {moneyMask(sellerPayment?.valueBasisCommission.toFixed(2))}
              </h4>
            </Col>
            <Col md="4">
              <p className="m-0">Qtd. Contratos</p>
              <h4 className="m-0">{sellerPayment?.qtdContract}</h4>
            </Col>
            <Col md="4">
              <p className="m-0">Vlr. Repasse:</p>
              <h4 className="m-0 text-success font-weight-bold">
                R$ {moneyMask(sellerPayment?.valueComissionSeller.toFixed(2))}
              </h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </SampleModalView>
  );
};
