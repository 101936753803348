import { useState, useEffect } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';

import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';

import { listLeadCsv } from 'services/Consigned/ClientSearchService';
import { listSellersPaymentComissionService } from 'services/Comission/SellerComissionService';
import { moneyMask } from 'utils/mask';
import InputIndicationView from 'components/Form/Input/InputIndicationView';
import { CheckoutPaymentSellerComponent } from './Component/CheckoutPaymentSellerComponent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ClosingCommissionView = () => {
  const history = useHistory();
  const [listData, setListData] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const [sellerPayment, setSellerPayment] = useState({});
  const [idxList, setIdxList] = useState(null);

  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    isPaid: null,
    indication: '',
    pageActual: 0,
    limit: 15,
  });

  const handleCsv = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await listLeadCsv({ ...filter, isCsv: true }).then((res) => {
      Swal.fire({
        icon: 'info',
        title: 'Sucesso',
        text: res.data.message,
      }).then((result) => {
        if (!result.isConfirmed) return;
        history.push('/admin/report-schedule');
      });
    });
  };

  const handleList = async () => {
    setLoadingList(true);
    setListData([]);
    listSellersPaymentComissionService(filter)
      .then(({ data }) => {
        setListData(data.resultSet);
        setLoadingList(false);
      })
      .catch((err) => {
        setLoadingList(false);
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handlePaidSeller = async (seller, idx) => {
    setSellerPayment(seller);
    setOpenModal(true);
    setIdxList(idx);
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <>
      <Header titlePage="Comissionamento - Fechamento de Comissão" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fa fa-times mr-2" /> Fechamento
            </h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Período Cadastro</Label>
                  <InputGroup>
                    <Input
                      id="dateStart"
                      name="dateStart"
                      type="date"
                      className="border"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateStart: ev.target.value,
                        });
                      }}
                    />
                    <Input
                      id="dateEnd"
                      name="dateEnd"
                      type="date"
                      className="border pl-3"
                      onChange={(ev) => {
                        setFilter({
                          ...filter,
                          dateEnd: ev.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Status</Label>
                  <select
                    id="isPaid"
                    name="isPaid"
                    className="form-control"
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        isPaid: ev.target.value,
                      });
                    }}
                  >
                    <option value="">SELECIONE</option>
                    <option value="WAITING">AGUARDANDO</option>
                    <option value="PAID">PAGO</option>
                    <option value="ALL">TODOS</option>
                  </select>
                </FormGroup>
              </Col>
              <Col md="4">
                <InputIndicationView filter={filter} setFilter={setFilter} />
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button
              color="success"
              onClick={() => {
                handleList();
              }}
              disabled={loadingList}
            >
              <i className="fas fa-search mr-2" />
              Exibir resultados{' '}
              {loadingList && <i className="fas fa-circle-notch fa-spin" />}
            </Button>
            <Button color="primary" onClick={handleCsv}>
              <i className="fas fa-file-csv mr-2" />
              Exportar em CSV
            </Button>
          </CardFooter>
        </Card>
        <Card className="mt-3 shadow bg-transparent">
          <CardBody className="rounded shadow bg-transparent">
            <Row>
              {listData.length > 0 ? (
                listData.map((register, index) => (
                  <Col
                    key={index}
                    className="d-flex align-items-stretch px-1 col-lg-4 col-md-6 col-12"
                  >
                    <Card className="shadow mb-4 w-100">
                      <CardBody className="w-100 px-3">
                        <div className="mt-2">
                          <Col className="px-1 mb-3">
                            <p className="m-0" style={{ fontSize: '0.9rem' }}>
                              Vendedor / Indicação
                            </p>
                            <strong style={{ fontSize: '1.2rem' }}>
                              {register?.name}
                            </strong>
                          </Col>
                          <div className="d-flex flex-xl-row flex-column mb-3">
                            <Col xl="6" className="px-1">
                              <p className="m-0" style={{ fontSize: '0.9rem' }}>
                                Quantidade
                              </p>
                              <strong style={{ fontSize: '1.2rem' }}>
                                {register?.qtdContract}
                              </strong>
                            </Col>
                            <Col xl="6" className="px-1 mt-xl-0 mt-2">
                              <p className="m-0" style={{ fontSize: '0.9rem' }}>
                                Produção
                              </p>
                              <strong style={{ fontSize: '1.2rem' }}>
                                R${' '}
                                {moneyMask(
                                  register?.valueBasisCommission.toFixed(2)
                                )}
                              </strong>
                            </Col>
                          </div>
                          <Card className="px-0 mb-2 shadow">
                            <CardHeader
                              className="px-2 py-2"
                              style={{ fontSize: '1.1rem' }}
                            >
                              <i className="fas fa-wallet" /> Repasse
                            </CardHeader>
                            <CardBody className="px-2 py-2 d-flex flex-column">
                              <p className="m-0">
                                Aguardando:{' '}
                                <strong className="text-primary">
                                  R${' '}
                                  {moneyMask(
                                    register?.valueComissionSeller.toFixed(2)
                                  )}
                                </strong>
                              </p>
                              <p className="m-0">
                                Pago:{' '}
                                <strong className="text-success">
                                  R${' '}
                                  {moneyMask(
                                    register?.valueComissionSellerPaid.toFixed(
                                      2
                                    )
                                  )}
                                </strong>
                              </p>
                            </CardBody>
                          </Card>
                        </div>
                        {register.valueComissionSeller > 0 && (
                          <>
                            <a
                              href={`/admin/comission/proposal?indicationId=${register.indicationId}&dateStart=${filter.dateStart || ''}&dateEnd=${filter.dateEnd || ''}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: 'inherit', textDecoration: 'none' }}
                            >
                              <Button
                                color="primary"
                                className="w-100 m-0 mt-2"
                              >
                                <i className="fa fa-list mr-2" />
                                VER CONTRATOS
                              </Button>
                            </a>

                            <Button
                              color="success"
                              className="w-100 m-0 mt-2"
                              onClick={() => handlePaidSeller(register, index)}
                            >
                              <i className="fa fa-check mr-2" />
                              PAGAR CONTRATOS
                            </Button>
                          </>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col md="12" className="d-flex justify-content-center">
                  <Alert color="secondary" className="m-0 p-0">
                    Nenhum registro encontrado...
                  </Alert>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>

        <CheckoutPaymentSellerComponent
          openModal={openModal}
          setOpenModal={setOpenModal}
          sellerPayment={sellerPayment}
          setSellerPayment={setSellerPayment}
          filter={filter}
          idxList={idxList}
          list={listData}
          setList={setListData}
        />
      </Container>
    </>
  );
};

export default ClosingCommissionView;
