import { useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { maskCPF } from 'utils/mask';
import InputIndicationView from './Input/InputIndicationView';
import InputOperatorView from './Input/InputOperatorView';
import { phoneMask } from 'utils/mask';
import SelectTagComponent from './Input/SelectTagComponent';
import InputFebrabanView from './Input/InputFebrabanView';
import InputBankView from './Input/InputBankView';

const FilterDefault = ({
  title,
  data,
  setData,
  handleSubmit,
  handleSubmitCsv,
  handleRedirectProposal,
  config = {
    showBtnExcel: true,
    showFilterStatusText: true,
    showBtnProposal: false,
    showFilterHasBalance: false,
    showFilterTag: false,
    showBtnAditional: false,
    btnAditional: null,
  },
  setToggleShowList = () => { },
}) => {
  const userDataConfig = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));

  useEffect(() => {
    const savedView = localStorage.getItem('IZIDEV_LIST_VIEW_CARD');
    setToggleShowList(savedView ? parseInt(savedView, 10) : 0);
  }, []);

  return (
    <>
      <Card>
        <CardHeader>
          <h1 className="m-0">{title}</h1>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Período</Label>
                <InputGroup>
                  <Input
                    id="dateStart"
                    name="dateStart"
                    type="date"
                    className="border"
                    onChange={(ev) => {
                      setData({ ...data, dateStart: ev.target.value });
                    }}
                  />
                  <Input
                    id="dateEnd"
                    name="dateEnd"
                    type="date"
                    className="border pl-3"
                    onChange={(ev) => {
                      setData({ ...data, dateEnd: ev.target.value });
                    }}
                  />

                  <select
                    className="form-control border pl-2"
                    id="typeDate"
                    name="typeDate"
                    onChange={(ev) => {
                      setData({ ...data, typeDate: ev.target.value });
                    }}
                    onBlur={(ev) => {
                      setData({ ...data, typeDate: ev.target.value });
                    }}
                  >
                    <option value="createdAt">DATA DE CADASTRO</option>
                    <option value="updatedAt">DATA DE ATUALIZAÇÃO</option>
                  </select>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md="3">
              <InputBankView
                placeholder="Selecione..."
                defaultValue={{
                  value: data.febrabanId,
                }}
                filter={data}
                setFilter={(e) => {
                  setData({
                    ...data,
                    febrabanId: e.febrabanId,
                  });
                }}
                isClearInput
              />
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>CPF do Cliente</Label>
                <Input
                  id="cpf"
                  name="cpf"
                  type="text"
                  value={data.cpf}
                  onChange={(ev) => {
                    setData({ ...data, cpf: maskCPF(ev.target.value) });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Nº Contrato</Label>
                <Input
                  id="numberProposal"
                  name="numberProposal"
                  type="text"
                  onChange={(ev) => {
                    setData({ ...data, numberProposal: ev.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="5">
              <InputIndicationView filter={data} setFilter={setData} />
            </Col>
            {config.useFilterOperator && (
              <Col md="4">
                <InputOperatorView filter={data} setFilter={setData} />
              </Col>
            )}
            <Col md="4">
              <FormGroup>
                <Label>Status izidev</Label>
                <select
                  id="statusDigitation"
                  name="statusDigitation"
                  className="form-control"
                  onChange={(ev) => {
                    setData({ ...data, statusIzidev: ev.target.value });
                  }}
                >
                  <option value="">TODOS</option>
                  <option value="5">SIMULAÇÃO INICIALIZADA</option>
                  <option value="1">INICIOU SIMULAÇÃO</option>
                  <option value="4">SIMULAÇÃO NÃO FINALIZADA</option>
                  <option value="2">DIGITAÇÃO REALIZADA</option>
                  <option value="3">
                    DIGITAÇÃO REALIZADA / NÃO FINALIZADA
                  </option>
                  <option value="6">DIGITAÇÃO INICIALIZADA</option>
                </select>
              </FormGroup>
            </Col>
            {config.showFilterStatusText && (
              <Col md="4">
                <FormGroup>
                  <Label>Status Banco - Texto</Label>
                  <Input
                    id="statusBankText"
                    name="statusBankText"
                    type="text"
                    onChange={(ev) => {
                      setData({ ...data, statusBankText: ev.target.value });
                    }}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="4">
              <FormGroup>
                <Label>Erro - Simulação</Label>
                <Input
                  id="error"
                  name="error"
                  type="text"
                  onChange={(ev) => {
                    setData({ ...data, error: ev.target.value });
                  }}
                />
              </FormGroup>
            </Col>
            {config.showFilterHasBalance && (
              <Col md="3">
                <FormGroup>
                  <Label>Possui Saldo</Label>
                  <select
                    id="hasBalance"
                    name="hasBalance"
                    className="form-control"
                    onChange={(ev) => {
                      setData({ ...data, hasBalance: ev.target.value });
                    }}
                  >
                    <option value="">Todos</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </FormGroup>
              </Col>
            )}
            <Col md="3">
              <FormGroup>
                <Label>Nº WhatsApp</Label>
                <Input
                  id="whatsappNumber"
                  name="whatsappNumber"
                  type="text"
                  value={data.whatsappNumber}
                  onChange={(ev) => {
                    setData({
                      ...data,
                      whatsappNumber: phoneMask(ev.target.value),
                    });
                  }}
                  placeholder="(00) 00000-0000"
                />
              </FormGroup>
            </Col>
            {config.showFilterTag && (
              <Col md="6">
                <SelectTagComponent
                  onChange={(e) => {
                    setData({
                      ...data,
                      tag: e,
                    });
                  }}
                />
              </Col>
            )}
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            color="success"
            onClick={handleSubmit}
            className="margin-button"
          >
            <i className="fas fa-search" /> Buscar
          </Button>
          {config.showBtnExcel && userDataConfig?.canExportExcel > 0 && (
            <>
              <Button
                color="success"
                onClick={handleSubmitCsv}
                className="margin-button"
              >
                <i className="fas fa-file-excel" /> Exportar Excel
              </Button>
            </>
          )}
          {config.showBtnProposal && (
            <>
              <Button
                color="primary"
                className="margin-button"
                onClick={handleRedirectProposal}
              >
                <i className="far fa-clipboard" /> Digitar Proposta
              </Button>
            </>
          )}

          {config.showBtnAditional && <>{config.btnAditional}</>}
        </CardFooter>
      </Card>
    </>
  );
};

export default FilterDefault;
