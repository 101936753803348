import { useState, useEffect } from 'react';
import {
  ModalBody,
  Modal,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Alert,
  CardHeader,
  Collapse,
  Badge,
  Table,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { SimulationFgts } from 'services/Digitation/SimulationService';
import { tableTaxService } from 'services/Digitation/IntegrationService';
import Swal from 'sweetalert2';
import { maskCPF, moneyMask, phoneMask, maskDate } from 'utils/mask';
import InfoSimulationDashboard from './simulationInfo';
import InputIndicationView from 'components/Form/Input/InputIndicationView';
import { tableTaxLotusMaisService } from 'services/Digitation/IntegrationService';
import { listAllTableService } from 'services/Comission/TableComissionService';
import { listBanksActiveService } from 'services/AccessBank/AccessBankService';
import dayjs from 'dayjs';
import { ShowClientProposalService } from 'services/Register/ClientService';
import { useRef } from 'react';

const DigitationFGTS = (props) => {
  const inputWhatsApp = useRef(null);
  const { isModal, state, dataSimule } = props;
  const [bankActiveFgts, setBankActiveFgts] = useState([]);

  const [showInstallment, setShowInstallment] = useState({
    febrabanId: null,
    collapse: false,
  });

  const handleShowInstallment = (febrabanId) => {
    setShowInstallment({
      febrabanId: febrabanId,
      collapse: true,
    });
  };

  const [collapse, setCollapse] = useState(false);
  const userData = JSON.parse(localStorage.getItem('IZIDEV_USER_DATA'));

  const toggle = () => {
    setCollapse(!collapse);
  };

  const [modalInfo, setModalInfo] = useState(false);
  const [simulationResult, setSimulationResult] = useState({});
  const [modal, setModal] = useState(false);
  const [multSimulation, setMultSimulation] = useState([]);
  const [simulation, setSimulation] = useState({
    name: '',
    birthDate: '',
    cpf: '',
    phone: '',
    token: '',
    table329: null,
    parc329: null,
    useSafe329: 0,
    table623: null,
    parc623: null,
  });
  const [validation] = useState({
    name: false,
    birthDate: false,
    cpf: false,
    phone: false,
  });
  const [tableSafra, setTableSafra] = useState([]);
  const [tableLotusMais, setTableLotusMais] = useState([]);
  const [tableFacta, setTableFacta] = useState([]);
  const [tableIcred, setTableIcred] = useState([]);
  const [tablePan, setTablePan] = useState([]);
  const [tableVctex, setTableVctex] = useState([]);
  const [tableUY3, setTableUY3] = useState([]);
  const [tableGrandinoBank, setTableGrandinoBank] = useState([]);
  const [tableNovoSaque, setTableNovoSaque] = useState([]);

  const handleListTableFacta = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '149')?.length <= 0)
      return;

    const result = await listAllTableService('149', { serviceId: 2 });
    if (!result) return;
    setTableFacta(result.resultSet);
  };

  const handleListTableUY3 = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '457')?.length <= 0)
      return;

    const result = await listAllTableService('457', { serviceId: 2 });
    if (!result) return;
    setTableUY3(result.resultSet);
  };

  const handleListTableIcred = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '329')?.length <= 0)
      return;

    const result = await listAllTableService('329', { serviceId: 2 });
    if (!result) return;
    setTableIcred(result.resultSet);
  };

  const handleListTablePAN = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '623')?.length <= 0)
      return;

    const result = await listAllTableService('623', { serviceId: 2 });
    if (!result) return;
    setTablePan(result.resultSet);
  };

  const handleListTableVctex = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '329-6')?.length <= 0)
      return;

    const result = await listAllTableService('329-6', { serviceId: 2 });
    if (!result) return;
    setTableVctex(result.resultSet);
  };

  const handleListTableGrandinoBank = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '329-5')?.length <= 0)
      return;

    const result = await listAllTableService('329-5', { serviceId: 2 });
    if (!result) return;
    setTableGrandinoBank(result.resultSet);
  };

  const handleListTableNovoSaque = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '274')?.length <= 0)
      return;

    const result = await listAllTableService('274', { serviceId: 2 });
    if (!result) return;
    setTableNovoSaque(result.resultSet);
  };

  const handleGetTableTaxSafra = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '074')?.length <= 0)
      return;

    await tableTaxService()
      .then((res) => {
        setTableSafra(res.data.resultSet);
      })
      .catch(() => {
        setTableSafra([]);
      });
  };

  const handleGetTableTaxLotusMais = async () => {
    if (bankActiveFgts.filter((bank) => bank.febraban == '324')?.length <= 0)
      return;

    await tableTaxLotusMaisService()
      .then((res) => {
        setTableLotusMais(res.data.resultSet);
      })
      .catch(() => {
        setTableLotusMais([]);
      });
  };

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };

  const closeModal = () => {
    setModal(false);
    state(false);
  };

  const handleChange = (event) => {
    if (event.target.name === 'name') {
      setSimulation({ ...simulation, name: event.target.value });
    }
    if (event.target.name === 'cpf') {
      setSimulation({ ...simulation, cpf: maskCPF(event.target.value) });
    }
    if (event.target.name === 'birthDate') {
      setSimulation({ ...simulation, birthDate: maskDate(event.target.value) });
    }
    if (event.target.name === 'phone') {
      setSimulation({ ...simulation, phone: phoneMask(event.target.value) });
    }
  };

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Realizando simulação',
      text: 'Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    SimulationFgts(simulation)
      .then((res) => {
        Swal.close();
        const result = res.data.result;

        if (res.data.multResult && res.data.multResult.length > 0) {
          setSimulationResult(res.data.result);
          setMultSimulation(res.data.multResult);
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            html: `
        <h2>Total FGTS</h2>
        <h1 class="text-danger">R$ ${result.valueFinally}</h1>
        <h2>Valor liberado para saque</h2>
        <h1 class="text-success">R$ ${result.valueLiberty}</h1>
        `,
            confirmButtonText: 'CONTINUAR DIGITAÇÃO',
            showCancelButton: true,
            cancelButtonText: 'SAIR',
          }).then((confirm) => {
            if (!confirm.isConfirmed) return;
            setSimulationResult(res.data.result);
            setModalInfo(true);
            closeModal();
          });
        }
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleDigitationProposal = (key) => {
    const simulation = multSimulation[key];
    setSimulationResult({
      ...simulationResult,
      febrabanId: simulation.febrabanId,
      valueFinally: simulation.totalCreditAccountFGTS.toFixed(2),
      valueLiberty: simulation.totalCreditLiberty.toFixed(2),
      table: simulation.table,
      parc: simulation.parc,
      useSafe: simulation.useSafe,
    });
    setModalInfo(true);
    closeModal();
  };

  const handleListBanksFgtsActive = async () => {
    listBanksActiveService().then(({ data }) => {
      setBankActiveFgts(data.resultSet);
    });
  };

  const handleGetClient = async (cpf) => {
    inputWhatsApp.current.focus();
    if (!cpf) return;

    Swal.fire({
      title: 'Buscando cliente',
      text: 'Por favor, aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await ShowClientProposalService(cpf.replace(/[^0-9]/g, ''))
      .then(({ data }) => {
        Swal.close();
        if (!data?.resultSet || !data?.resultSet?.name) {
          setSimulation({
            ...simulation,
            name: '',
            birthDate: '',
            phone: '',
          });
          return;
        }
        setSimulation({
          ...simulation,
          name: data.resultSet.name,
          birthDate: data.resultSet.birthDate,
          phone: data.resultSet.whatsappNumber,
        });
      })
      .catch(() => {
        Swal.close();
      });
  };

  useEffect(() => {
    setMultSimulation([]);

    if (!isModal) return;

    handleListBanksFgtsActive();

    setCollapse(false);
    setSimulation({
      name: dataSimule?.name ?? '',
      birthDate: dataSimule?.birthDate ?? '',
      cpf: maskCPF(dataSimule?.cpf ?? ''),
      phone: phoneMask(dataSimule?.whatsappNumber ?? ''),
      indicationId: dataSimule?.indicationId,
      nameIndication:
        dataSimule?.nameIndication + ` - ` + dataSimule?.codeIndication,
    });

    setModal(isModal);
  }, [isModal]);

  useEffect(() => {
    if (bankActiveFgts.length <= 0) return;

    handleGetTableTaxSafra();
    handleGetTableTaxLotusMais();
    handleListTableFacta();
    handleListTableIcred();
    handleListTablePAN();
    handleListTableVctex();
    handleListTableGrandinoBank();
    handleListTableNovoSaque();
    handleListTableUY3();
  }, [bankActiveFgts]);

  return (
    <div>
      <Modal isOpen={modal} toggle={handleToggle} size="xl">
        <ModalHeader toggle={closeModal}>
          <h1>
            <i className="far fa-sticky-note" /> Simulação
          </h1>
        </ModalHeader>
        <ModalBody>
          <div className="shadow p-4 rounded">
            <Row>
              <Col xl="6">
                <FormGroup>
                  <Label>
                    CPF <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="cpf"
                    className="form-control form-control-sm"
                    placeholder="000.000.000-00"
                    value={simulation.cpf}
                    onChange={handleChange}
                    onBlur={(ev) => {
                      handleChange(ev);
                      handleGetClient(ev.target.value);
                    }}
                    invalid={validation.cpf}
                  />
                </FormGroup>
              </Col>
              <Col xl="6">
                <FormGroup>
                  <Label>
                    Celular WhatsApp <span className="text-danger">*</span>
                  </Label>
                  <InputGroup>
                    <Input
                      type="text"
                      name="phone"
                      className="form-control form-control-sm"
                      placeholder="(00) 0 0000-0000"
                      value={simulation.phone}
                      onChange={handleChange}
                      onBlur={handleChange}
                      invalid={validation.phone}
                      ref={inputWhatsApp}
                    />
                    {simulation.phone && (
                      <InputGroupAddon addonType="append">
                        <a
                          className="btn btn-success btn-sm"
                          href={`https://wa.me/55${simulation.phone.replace(
                            /[^0-9]/g,
                            ''
                          )}`}
                          target="_blank"
                        >
                          <i className="fab fa-whatsapp" />
                        </a>
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl="8">
                <FormGroup>
                  <Label>Nome Completo</Label>
                  <Input
                    type="text"
                    name="name"
                    className="form-control form-control-sm"
                    placeholder="Informe o nome completo do cliente"
                    value={simulation.name}
                    onChange={handleChange}
                    onBlur={handleChange}
                    invalid={validation.name}
                  />
                </FormGroup>
              </Col>
              <Col xl="4">
                <FormGroup>
                  <Label>Data de Nascimento</Label>
                  <Input
                    type="text"
                    placeholder="--/--/----"
                    name="birthDate"
                    className="form-control form-control-sm"
                    value={simulation.birthDate}
                    onChange={(e) => {
                      const maskedValue = maskDate(e.target.value);
                      handleChange({
                        target: { name: 'birthDate', value: maskedValue },
                      });
                    }}
                    onBlur={handleChange}
                    invalid={validation.birthDate}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <InputIndicationView
                    filter={simulation}
                    setFilter={(ev) => {
                      setSimulation({
                        ...simulation,
                        indicationId: ev?.indicationId,
                      });
                    }}
                    defaultValue={
                      simulation?.indicationId > 0
                        ? {
                            value: simulation?.indicationId ?? null,
                            label: simulation?.nameIndication ?? null,
                          }
                        : {}
                    }
                    isClearInput={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            {userData?.showAdvancedOptionsFgts == 1 ? (
              <>
                <button
                  className="btn btn-secondary btn-lg w-100"
                  onClick={toggle}
                >
                  <i className="fab fa-buromobelexperte" /> CONFIGURAÇÃO
                  AVANÇADA
                </button>
                <Collapse isOpen={collapse}>
                  <Card className="shadow mt-2">
                    <CardBody>
                      <Alert color="info">
                        Esta configuração tem como finalidade ajudar na
                        simulação para os bancos citados abaixo:
                      </Alert>
                      <Row>
                        {bankActiveFgts.filter(
                          (bank) => bank.febraban == '329-5'
                        )?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                GRANDINO BANK{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12" lg="6">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table3295: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableGrandinoBank.map((table, key) => (
                                          <option
                                            value={table.tableId}
                                            key={key}
                                          >
                                            {table.tableName}
                                          </option>
                                        ))}
                                      </select>
                                    </FormGroup>
                                  </Col>

                                  <Col md="12" lg="6">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc3295: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="1">1 Parcela</option>
                                        <option value="2">2 Parcelas</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                        <option value="11">11 Parcelas</option>
                                        <option value="12">12 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '329')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                iCred{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table329: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableIcred.map(
                                          (icredTab, keyIcred) => (
                                            <option
                                              value={icredTab.tableId}
                                              key={keyIcred}
                                            >
                                              {icredTab.tableName}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc329: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="1">1 Parcela</option>
                                        <option value="2">2 Parcelas</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                        <option value="11">11 Parcelas</option>
                                        <option value="12">12 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label>Seguro</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            useSafe329: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="0">NÃO</option>
                                        <option value="1">SIM</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '074')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                Safra{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table074: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableSafra.length > 0 ? (
                                          tableSafra.map((register, key) => (
                                            <option
                                              value={register.id}
                                              key={key}
                                            >
                                              {register.descricao}
                                            </option>
                                          ))
                                        ) : (
                                          <></>
                                        )}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '623')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                PAN{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table623: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>

                                        {tablePan.map((panTab, keyPan) => (
                                          <option
                                            value={panTab.tableId}
                                            key={keyPan}
                                          >
                                            {panTab.tableName}
                                          </option>
                                        ))}
                                      </select>
                                    </FormGroup>
                                  </Col>

                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc623: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="1">1 Parcela</option>
                                        <option value="2">2 Parcelas</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>

                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label>Seguro</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            useSafe623: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="0">NÃO</option>
                                        <option value="1">SIM</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '626')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                C6 CONSIG{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12" lg="12">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc626: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="1">1 Parcela</option>
                                        <option value="2">2 Parcelas</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '149')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                Facta Financeira{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12" lg="12">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table149: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableFacta.map((register, key) => (
                                          <option
                                            key={key}
                                            value={register.tableId}
                                          >
                                            {register.tableName}
                                          </option>
                                        ))}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '324')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                LOTUS MAIS{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table324: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableLotusMais.length > 0 ? (
                                          tableLotusMais.map(
                                            (register, key) => (
                                              <option
                                                value={register.id}
                                                key={key}
                                              >
                                                {register.nickname}
                                              </option>
                                            )
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc324: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="1">1 Parcela</option>
                                        <option value="2">2 Parcelas</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                  <Col md="12" lg="6">
                                    <FormGroup>
                                      <Label>Seguro</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            useSafe324: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="0">NÃO</option>
                                        <option value="1">SIM</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {bankActiveFgts.filter(
                          (bank) => bank.febraban == '329-6'
                        )?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                VCTEX{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="12">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table3296: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableVctex.length > 0 ? (
                                          tableVctex.map((register, key) => (
                                            <option
                                              value={register.tableId}
                                              key={key}
                                            >
                                              {register.tableName}
                                            </option>
                                          ))
                                        ) : (
                                          <></>
                                        )}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                        {bankActiveFgts.filter((bank) => bank.febraban == '457')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                UY3{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table457: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableUY3.length > 0 ? (
                                          tableUY3.map((register, key) => (
                                            <option
                                              value={register.tableId}
                                              key={key}
                                            >
                                              {register.tableName}
                                            </option>
                                          ))
                                        ) : (
                                          <></>
                                        )}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc457: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                        <option value="11">11 Parcelas</option>
                                        <option value="12">12 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}

                        {bankActiveFgts.filter((bank) => bank.febraban == '274')
                          ?.length > 0 && (
                          <Col md="12" lg="6">
                            <Card className="shadow mt-1 mb-1">
                              <CardHeader className="bg-secondary">
                                NOVO SAQUE{' '}
                                <Badge color="info">
                                  Configuração opcional
                                </Badge>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Tabela</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            table274: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        {tableNovoSaque.length > 0 ? (
                                          tableNovoSaque.map(
                                            (register, key) => (
                                              <option
                                                value={register.tableId}
                                                key={key}
                                              >
                                                {register.tableName}
                                              </option>
                                            )
                                          )
                                        ) : (
                                          <></>
                                        )}
                                      </select>
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Prazo</Label>
                                      <select
                                        className="form-control form-control-sm"
                                        onChange={(ev) => {
                                          setSimulation({
                                            ...simulation,
                                            parc274: ev.target.value,
                                          });
                                        }}
                                      >
                                        <option value="">Selecione...</option>
                                        <option value="1">1 Parcela</option>
                                        <option value="2">2 Parcelas</option>
                                        <option value="3">3 Parcelas</option>
                                        <option value="4">4 Parcelas</option>
                                        <option value="5">5 Parcelas</option>
                                        <option value="6">6 Parcelas</option>
                                        <option value="7">7 Parcelas</option>
                                        <option value="8">8 Parcelas</option>
                                        <option value="9">9 Parcelas</option>
                                        <option value="10">10 Parcelas</option>
                                      </select>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </>
            ) : (
              <></>
            )}

            {userData?.showAdvancedOptionsFgts == 2 ? (
              <>
                <button
                  className="btn btn-secondary btn-lg w-100"
                  onClick={toggle}
                >
                  <i className="fab fa-buromobelexperte" /> CONFIGURAÇÃO
                  AVANÇADA
                </button>
                <Collapse isOpen={collapse}>
                  <Card className="shadow mt-2">
                    <CardBody>
                      <Alert color="info">
                        Esta configuração tem como finalidade ajudar na
                        simulação para os bancos citados abaixo:
                      </Alert>
                      <Row>
                        <Col md="12" lg="12">
                          <Card className="shadow mt-1 mb-1">
                            <CardHeader className="bg-secondary">
                              Grandino Bank{' '}
                              <Badge color="info">Configuração opcional</Badge>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md="6" lg="6">
                                  <FormGroup>
                                    <Label>Tabela</Label>
                                    <select
                                      className="form-control form-control-sm"
                                      onChange={(ev) => {
                                        setSimulation({
                                          ...simulation,
                                          table3295: ev.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Selecione...</option>
                                      {tableGrandinoBank.map((table, key) => (
                                        <option value={table.tableId} key={key}>
                                          {table.tableName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormGroup>
                                </Col>
                                <Col md="6" lg="6">
                                  <FormGroup>
                                    <Label>Prazo</Label>
                                    <select
                                      className="form-control form-control-sm"
                                      onChange={(ev) => {
                                        setSimulation({
                                          ...simulation,
                                          parc3291: ev.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">Selecione...</option>
                                      <option value="1">1 Parcela</option>
                                      <option value="2">2 Parcelas</option>
                                      <option value="3">3 Parcelas</option>
                                      <option value="4">4 Parcelas</option>
                                      <option value="5">5 Parcelas</option>
                                      <option value="6">6 Parcelas</option>
                                      <option value="7">7 Parcelas</option>
                                      <option value="8">8 Parcelas</option>
                                      <option value="9">9 Parcelas</option>
                                      <option value="10">10 Parcelas</option>
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Collapse>
              </>
            ) : (
              <></>
            )}

            {multSimulation.length > 0 ? (
              <>
                <hr />
                <h3>Condições de crédito</h3>
                {multSimulation.map((register, key) => (
                  <Card key={key} className="mb-2 shadow">
                    {register.status ? (
                      <>
                        <CardBody>
                          <Row>
                            <Col xl="12">
                              <p className="m-0">
                                Banco:{' '}
                                <strong>
                                  {register.febrabanId} - {register.nameBank}
                                </strong>
                              </p>
                            </Col>
                            <Col xl="3">
                              {register?.tableName && (
                                <p className="m-0">
                                  Tabela: <strong>{register?.tableName}</strong>
                                </p>
                              )}
                            </Col>
                            <Col xl="4">
                              <div>
                                Vlr. Contrato:{' '}
                                <strong>
                                  R${' '}
                                  {moneyMask(
                                    register.totalCreditAccountFGTS.toFixed(2)
                                  )}
                                </strong>
                              </div>
                              <div>
                                Vlr. Liberado:{' '}
                                <strong>
                                  R${' '}
                                  {register.totalCreditLiberty > 0
                                    ? moneyMask(
                                        register.totalCreditLiberty.toFixed(2)
                                      )
                                    : '0,00'}
                                </strong>
                              </div>
                            </Col>
                            <Col className="text-right" xl="5">
                              <InputGroup>
                                {register?.totalParc?.length > 0 && (
                                  <Button
                                    color="primary"
                                    className="w-50 m-0"
                                    onClick={() =>
                                      handleShowInstallment(register.febrabanId)
                                    }
                                  >
                                    <i className="far fa-folder-open" />{' '}
                                    PARCELAS
                                  </Button>
                                )}
                                <Button
                                  color="success"
                                  className="w-50 m-0"
                                  onClick={() => {
                                    handleDigitationProposal(key);
                                  }}
                                >
                                  <i className="fas fa-fire" /> SELECIONAR
                                </Button>
                              </InputGroup>
                            </Col>
                          </Row>
                          {register?.totalParc?.length > 0 && (
                            <Collapse
                              isOpen={
                                showInstallment?.febrabanId ===
                                  register?.febrabanId &&
                                showInstallment?.collapse === true
                              }
                            >
                              {register?.iof > 0 && (
                                <p className="mt-2">
                                  Vlr. IOF:{' '}
                                  <span className="font-weight-bold">
                                    R$ {moneyMask(register?.iof)}
                                  </span>
                                </p>
                              )}
                              {register?.tac > 0 && (
                                <p className="mt-2">
                                  Vlr. TC:{' '}
                                  <span className="font-weight-bold">
                                    R${' '}
                                    {moneyMask(
                                      Number(register?.tac ?? 0).toFixed(2)
                                    )}
                                  </span>
                                </p>
                              )}

                              {register?.iof > 0 && register?.tac > 0 && (
                                <p className="mt-2">
                                  Vlr. Operação:{' '}
                                  <span className="font-weight-bold">
                                    R${' '}
                                    {moneyMask(
                                      Number(
                                        register.totalCreditLiberty +
                                          register?.iof +
                                          register?.tac
                                      ).toFixed(2)
                                    )}
                                  </span>
                                </p>
                              )}
                              <Card className="shadow mt-3">
                                <CardHeader>
                                  <h4>Parcelas</h4>
                                </CardHeader>
                                <CardBody>
                                  <div className="table-responsive">
                                    <Table striped={true} bordered={true}>
                                      <thead>
                                        <tr>
                                          <td>Parcela</td>
                                          <td>Vencimento</td>
                                          <td>Valor</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {register?.totalParc.map(
                                          (installment, key) => (
                                            <>
                                              <tr key={key}>
                                                <td>{installment.parc}</td>
                                                <td>{installment.date}</td>
                                                <td>
                                                  R${' '}
                                                  {moneyMask(
                                                    Number(
                                                      installment.value
                                                    ).toFixed(2)
                                                  )}
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            </Collapse>
                          )}
                        </CardBody>
                      </>
                    ) : (
                      <CardBody>
                        Banco: {register.febrabanId} - {register.nameBank}
                        <br />
                        <h4 className="text-danger">{register.message}</h4>
                      </CardBody>
                    )}
                  </Card>
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </ModalBody>
        <ModalFooter className="text-right">
          <Button
            className="btn btn-success btn-md"
            onClick={handleSubmit}
            disabled={!simulation.cpf || !simulation.phone}
          >
            <i className="fas fa-clipboard-check" /> SIMULAR PROPOSTA
          </Button>
          <Button className="btn btn-danger btn-md" onClick={closeModal}>
            <i className="fas fa-times" /> SAIR
          </Button>
        </ModalFooter>
      </Modal>
      <InfoSimulationDashboard
        isModal={modalInfo}
        state={setModalInfo}
        data={simulationResult}
      />
    </div>
  );
};
export default DigitationFGTS;
