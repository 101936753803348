import {
  listAllTablesService,
  activeInactiveRuleService,
  createRuleService,
} from 'services/AccessBank/AccessBankService';
import Swal from 'sweetalert2';

const { Title } = require('components/Headers/styles');
const { useEffect } = require('react');
const { useState } = require('react');
const {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Table,
  Badge,
  Alert,
} = require('reactstrap');
const { listRuleService } = require('services/AccessBank/AccessBankService');
const { moneyMask } = require('utils/mask');

const RuleTableSimulationComponent = ({ febrabanId }) => {
  const [tables, setTables] = useState([]);

  const [list, setList] = useState([]);
  const [rule, setRule] = useState({
    febrabanId: null,
    tableAmountId: null,
    tableAmountName: null,
    valueStart: null,
    valueEnd: null,
    isErrorTac: 0,
  });

  const handleListTable = () => {
    listAllTablesService(febrabanId, {
      serviceId: 2,
    })
      .then(({ data }) => {
        setTables(data.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleList = () => {
    setRule({
      ...rule,
      febrabanId: febrabanId,
    });

    listRuleService({
      febrabanId: febrabanId,
    })
      .then(({ data }) => {
        setList(data.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = () => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja cadastrar esta regra?',
      showDenyButton: true,
      denyButtonText: 'Não, sair',
      confirmButtonText: 'Sim, salvar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      createRuleService(rule)
        .then(() => {
          Swal.close();
          handleList();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const handleActiveInactive = (id) => {
    Swal.fire({
      icon: 'question',
      title: 'Confirmação',
      text: 'Tem certeza que deseja realizar esta ação?',
      showDenyButton: true,
      denyButtonText: 'Não, sair',
      confirmButtonText: 'Sim, salvar',
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;
      Swal.fire({
        title: 'Salvando',
        text: 'Por favor, aguarde.',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      activeInactiveRuleService(id)
        .then(() => {
          Swal.close();
          handleList();
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  useEffect(() => {
    if (!febrabanId) return;
    handleListTable();
    handleList();
  }, []);

  return (
    <div className="bg-secondary border p-4 rounded mt-4">
      <h3>Regra - Tabelas auto contratação - FGTS</h3>
      <Alert color="primary">
        <h2 className="mt-0">Informações importantes</h2>
        <ul>
          <li>
            <strong>Atenção: </strong> O sistema considerará as 3 primeiras
            regras ativas, de forma que não impacte na simulação. As regras
            poderão causar aumento no tempo máximo das simulações.
          </li>
          <li>
            Configurar apenas a condição específica que altera a tabela (
            <strong>exemplo:</strong> do valor 50 a 100, tabela X), o que
            estiver fora dessa condição, o sistema considerará a tabela padrão
            configurada.
          </li>
          <li>
            Funcionalidade em fase <strong>BETA</strong>: problemas ou
            sugestões,{' '}
            <strong>
              <a
                href="https://wa.me/551150439783?text=Ol%C3%A1%20gostaria%20de%20tirar%20uma%20d%C3%BAvida%20sobre%20a%20regra%20de%20simula%C3%A7%C3%A3o"
                target="_blank"
                className="text-secondary"
              >
                favor entrar em contato no suporte.
              </a>
            </strong>
            .
          </li>
        </ul>
      </Alert>
      <Card className="shadow">
        <CardHeader>
          <Title>Cadastrar Condição</Title>
        </CardHeader>
        <CardBody>
          <Row>
            {(febrabanId === '329-5' ||
              febrabanId === '329-6' ||
              febrabanId === '329-4' ||
              febrabanId === '324') && (
              <Col md="4">
                <FormGroup>
                  <Label>ERRO</Label>
                  <select
                    className="form-control"
                    value={rule.isErrorTac}
                    onChange={(ev) => {
                      setRule({
                        ...rule,
                        isErrorTac: ev.target.value,
                        valueStart: '0,00',
                        valueEnd: '999.999,99',
                      });
                    }}
                  >
                    <option value="0">NÃO</option>
                    <option value="1">SIM</option>
                  </select>
                </FormGroup>
              </Col>
            )}
            {rule.isErrorTac > 0 && febrabanId === '324' && (
              <Col md="4">
                <FormGroup>
                  <Label>Mensagem de erro</Label>
                  <Input
                    type="text"
                    value={rule.messageError}
                    onChange={(ev) => {
                      setRule({
                        ...rule,
                        messageError: ev.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            )}
            <Col md="4">
              <FormGroup>
                <Label>Tabela</Label>
                <select
                  type="text"
                  autoComplete="false"
                  value={rule.tableAmountId}
                  className="form-control"
                  onChange={(ev) => {
                    var index = ev.nativeEvent.target.selectedIndex;
                    setRule({
                      ...rule,
                      tableAmountId: ev.target.value,
                      tableAmountName: ev.nativeEvent.target[index].text,
                    });
                  }}
                >
                  <option value="" selected disabled>
                    Selecione...
                  </option>
                  {tables.length > 0 &&
                    tables.map((table, key) => (
                      <option key={key} value={table.tableId}>
                        {table.tableName}
                      </option>
                    ))}
                </select>
              </FormGroup>
            </Col>
            {rule.isErrorTac == 0 && (
              <>
                <Col md="4">
                  <FormGroup>
                    <Label>Valor Inicial</Label>
                    <Input
                      type="text"
                      placeholder="0,00"
                      value={rule.valueStart}
                      onChange={(ev) => {
                        setRule({
                          ...rule,
                          valueStart: moneyMask(ev.target.value),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Valor Final</Label>
                    <Input
                      type="text"
                      placeholder="0,00"
                      value={rule.valueEnd}
                      onChange={(ev) => {
                        setRule({
                          ...rule,
                          valueEnd: moneyMask(ev.target.value),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>
          {rule.isErrorTac == 1 && (
            <Alert color="secondary">
              Ao selecionar a opção <strong>ERRO TC</strong>, o sistema irá
              utilizar a tabela selecionada sempre que gerar o seguinte erro:{' '}
              <strong>
                Tabela de financiamento indisponível para este CPF devido à
                cobrança de TC!
              </strong>
            </Alert>
          )}
        </CardBody>
        <CardFooter>
          <Button color="success" onClick={handleSubmit}>
            Adicionar regra
          </Button>
        </CardFooter>
      </Card>
      {list.length > 0 && (
        <Card className="shadow mt-2">
          <CardHeader>
            <Title>Condições cadastradas</Title>
          </CardHeader>
          <Table responsive>
            <thead>
              <tr className="bg-secondary">
                <th>TABELA</th>
                <th>CONDIÇÃO</th>
                <th>DATAS</th>
                <th>STATUS</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list.map((rule, key) => (
                <tr key={key}>
                  <td className="align-middle">
                    <p>
                      {rule.tableAmountId} - {rule.tableAmountName} |{' '}
                      {rule.isErrorTac > 0 && (
                        <Badge color="info">
                          <strong>REGRA TC</strong>
                        </Badge>
                      )}
                    </p>
                    {rule.messageError && (
                      <p>
                        Erro: <strong>{rule.messageError}</strong>
                      </p>
                    )}
                  </td>
                  <td className="align-middle">
                    <p className="m-0">
                      Valor inicial:{' '}
                      <strong>{moneyMask(rule.valueStart)}</strong>
                    </p>
                    <p className="m-0">
                      Valor final: <strong>{moneyMask(rule.valueEnd)}</strong>
                    </p>
                  </td>
                  <td className="align-middle">
                    <p className="m-0">
                      Data cadastro: <strong>{rule.createdAt}</strong>
                    </p>
                    <p className="m-0">
                      Data última atualização: <strong>{rule.updateAt}</strong>
                    </p>
                  </td>
                  <td className="align-middle">
                    <Badge
                      color={rule.active == 1 ? 'success' : 'danger'}
                      className="font-16"
                    >
                      {rule.active == 1 ? 'ATIVO' : 'INATIVO'}
                    </Badge>
                  </td>
                  <td className="align-middle">
                    <Button
                      color={rule.active == 0 ? 'success' : 'danger'}
                      size="sm"
                      onClick={() => {
                        handleActiveInactive(rule.id);
                      }}
                    >
                      {rule.active == 1 ? (
                        <>
                          <i className="fas fa-minus-circle" /> INATIVAR
                        </>
                      ) : (
                        <>
                          <i className="fas fa-check-circle" /> ATIVAR
                        </>
                      )}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}
    </div>
  );
};

export default RuleTableSimulationComponent;
