import React, { useState, useEffect } from 'react';
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { resetPassword, AuthService } from 'services/AuthService';
import Swal from 'sweetalert2';
import logo from './../../../assets/img/logo/logo-dark.png';
import { promoterStyles } from 'services/Promoter/PromoterService';

const NewLogin = () => {
  const mainContent = React.useRef(null);
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');
  const [lock, setLock] = useState(true);
  const [token, setToken] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const history = useHistory();
  const [logoPromoter, setLogoPromoter] = useState(logo);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { nameSlug } = useParams();

  const handlePromoterStyles = async () => {
    promoterStyles(nameSlug).then(({ data }) => {
      if (data?.promoterStyles?.logo) {
        localStorage.setItem(
          'IZIDEV_CONFIG_CRM',
          JSON.stringify({
            nameSlug: nameSlug,
            pathLogin: `/login/${nameSlug}`,
            logo: data?.promoterStyles?.logo,
          })
        );
        setLogoPromoter(data?.promoterStyles?.logo);
      }
    });
  };

  useEffect(() => {
    if (nameSlug) {
      setLogoPromoter('');
      handlePromoterStyles();
    } else {
      localStorage.setItem(
        'IZIDEV_CONFIG_CRM',
        JSON.stringify({
          nameSlug: '',
          pathLogin: `/auth`,
          logo: logo,
        })
      );
    }
  }, []);

  useEffect(() => {
    localStorage.removeItem('IZIDEV_AUTH_TOKEN');

    setLogin(document.querySelector('#email').value ?? '');
    setPass(document.querySelector('#pass').value ?? '');

    if (login !== '' && pass !== '' && token) {
      setLock(false);
    }
  }, [refresh, login, pass]);

  const handleChange = async (event) => {
    if (event.target.name === 'email') {
      setLogin(event.target.value ?? '');
    }

    if (event.target.name === 'pass' ?? '') {
      setPass(event.target.value);
    }

    if (login !== '' && pass !== '') {
      setLock(false);
    }

    if (login == '' || pass == '') {
      setLock(true);
    }
  };

  const handleResetPassword = async () => {
    const { value: email } = await Swal.fire({
      title: 'Digite seu email',
      input: 'email',
      inputPlaceholder: 'EMAIL',
    });

    if (email) {
      Swal.fire({
        title: 'Carregando, Por favor aguarde...',
        message: 'Por favor aguarde',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      await resetPassword({
        email: email,
      })
        .then(async () => {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: 'Uma nova senha foi enviada no seu email',
          });
        })
        .catch((err) => {
          const code = !err.response ? '00000' : err.response.data.code;
          const message = !err.response
            ? 'Ocorreu um erro inesperado'
            : err.response.data.message;
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `[${code}] ${message}`,
          });
        });
    }
  };

  const handleSubmit = async (event) => {
    setRefreshReCaptcha((r) => !r);
    if (login == '' || pass == '') {
      setLock(true);
      return;
    }
    Swal.fire({
      title: 'Realizando login',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    event.preventDefault();

    setLock(true);

    await AuthService({
      email: login,
      password: pass,
      token: token,
      nameSlug: nameSlug,
    })
      .then(async (res) => {
        Swal.close();
        await localStorage.setItem('IZIDEV_AUTH_TOKEN', res.data.resultSet);
        await localStorage.setItem('FIRST_LOGIN_STEP', res.data.step);
        await localStorage.setItem(
          'IZIDEV_MODULE_SYSTEM',
          JSON.stringify(res.data.modules)
        );
        await localStorage.setItem(
          'IZIDEV_AUTH_ISINDICATION',
          res.data.isIndication
        );
        await localStorage.setItem(
          'IZIDEV_USER_DATA',
          JSON.stringify(res.data.user)
        );
        const url = `/admin${res?.data?.mainScreen ?? '/index'}`;
        await history.push(url);
      })
      .catch((err) => {
        setRefresh(Math.random());
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        })
          .then((confirm) => {
            if (confirm.isConfirmed && code == 31039) {
              window.location.reload(false);
            }
          })
          .finally(() => {
            setLock(false);
          });
      });
  };

  return (
    <Container
      id="izidev2023"
      className="w-100 h-100 p-0 bg-white"
      ref={mainContent}
      fluid
    >
      <Row className="h-100 m-0">
        <Col md="5" className="h-100">
          <Container
            className="h-100 d-flex align-items-center justify-content-center"
            fluid
          >
            <div style={{ minWidth: 350 }}>
              <div className="mb-5">
                <img
                  id="logo-izidev"
                  src={logoPromoter}
                  alt="logo"
                  height="100%"
                />
              </div>
              <h2 className="m-0">Bem-vindo(a)!</h2>
              <p className="mt-0">
                Informe os dados abaixo, para acessar o sistema
              </p>
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Informe seu e-mail"
                      type="email"
                      autoComplete="new-email"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      id="pass"
                      name="pass"
                      placeholder="Informe sua Senha"
                      type="password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="btn-izidev w-100"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={lock}
                  >
                    Realizar login
                  </Button>
                </div>
              </Form>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-primary"
                    href="#"
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleResetPassword();
                    }}
                  >
                    <small>Esqueci minha senha!</small>
                  </a>
                </Col>

                {/* {!nameSlug && (
                  <Col className="text-right" xs="6">
                    <a className="text-text-primary" href="/register">
                      <small>Criar uma nova conta</small>
                    </a>
                  </Col>
                )} */}
              </Row>
            </div>
          </Container>
        </Col>
        <Col md="7" className="h-100 bg-izidev-new" />
      </Row>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LeCLQ4gAAAAALOn_07B4dQAbVB78h4JTUmZhO19"
        refreshReCaptcha={refreshReCaptcha}
      >
        <GoogleReCaptcha onVerify={setToken} />
      </GoogleReCaptchaProvider>
    </Container>
  );
};

export default NewLogin;
