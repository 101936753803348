import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Row,
  Col,
} from 'reactstrap';
import { insertIndication } from 'services/Promoter/IndicationService';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';

const RegisterAccessBank = (props) => {
  const { className, isModal, state, list } = props;

  const [modal, setModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [data, setData] = useState({
    name: '',
    contactPhone: null,
    febrabanId: null,
    numberAgency: null,
    numberAccount: null,
    typeAccount: null,
    pix: null,
  });

  const handleSetValue = async (event) => {
    if (event.target.id === 'name') {
      setData({
        ...data,
        name: event.target.value,
      });
    }
    if (event.target.id === 'contactPhone') {
      setData({
        ...data,
        contactPhone: phoneMask(event.target.value),
      });
    }
    if (event.target.id === 'contactMessage') {
      setData({
        ...data,
        contactMessage: event.target.value,
      });
    }
    if (event.target.id === 'bank') {
      setData({
        ...data,
        febrabanId: event.target.value,
      });
    }
    if (event.target.id === 'agency') {
      setData({
        ...data,
        numberAgency: event.target.value,
      });
    }
    if (event.target.id === 'numberAccount') {
      setData({
        ...data,
        numberAccount: event.target.value,
      });
    }
    if (event.target.id === 'typeAccount') {
      setData({
        ...data,
        typeAccount: event.target.value,
      });
    }
    if (event.target.id === 'pix') {
      setData({
        ...data,
        pix: event.target.value,
      });
    }

    setIsBlocked(true);
    if (data.name !== '') {
      setIsBlocked(false);
    }
  };

  const handleSubmit = async () => {
    setIsBlocked(true);
    await insertIndication(data)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Parabens!',
          text: `O registro foi inserido com sucesso`,
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            list();
            setIsBlocked(false);
            closeModal();
            setData({
              name: '',
              contactPhone: '',
              febrabanId: null,
              numberAgency: null,
              numberAccount: null,
              typeAccount: null,
              pix: null,
            });
          }
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleToggle = () => {
    setModal(!modal);
    state(!modal);
  };
  const closeModal = () => {
    setModal(false);
    state(false);
  };

  useEffect(() => {
    setModal(isModal);
  }, [isModal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        className={className}
        backdrop={true}
        size="lg"
      >
        <ModalHeader toggle={closeModal}>Inserir Registro</ModalHeader>
        <ModalBody>
          <Alert color="success">
            <i className="fas fa-question-circle" /> Os dados do indicado serão
            utilizados para identificar uma venda feita a partir de um link de
            indicação.
          </Alert>
          <Row>
            <Col md={8}>
              <div className="form-group">
                <label htmlFor="clientId">
                  Nome do Indicado ou Usuario digitador{' '}
                  <span className="badge badge-info">Obrigatório</span>
                </label>
                <input
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Informe o nome da Indicação"
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-group">
                <label htmlFor="clientId">Whatsapp de suporte</label>
                <input
                  id="contactPhone"
                  name="contactPhone"
                  value={data.contactPhone}
                  className="form-control"
                  placeholder="Ex: (11) 9 999665533"
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label htmlFor="clientId">Mensagem padrão suporte</label>
                <input
                  id="contactMessage"
                  name="contactMessage"
                  value={data.contactMessage}
                  className="form-control"
                  placeholder=""
                  onChange={handleSetValue}
                  onBlur={handleSetValue}
                />
              </div>
            </Col>
          </Row>
          <div>
            <h3>Dados bancários</h3>
            <div className="bg-secondary p-3">
              <Row>
                <Col xl="12">
                  <div className="form-group">
                    <label htmlFor="bank">Banco</label>
                    <select
                      id="bank"
                      name="bank"
                      className="form-control"
                      onChange={handleSetValue}
                      onBlur={handleSetValue}
                    >
                      <option value="">SELECIONE</option>
                      <option value="104">CAIXA ECONOMICA FEDERAL</option>
                      <option value="001">BANCO DO BRASIL S.A.</option>
                      <option value="237">BANCO BRADESCO</option>
                      <option value="341">BANCO ITAÚ S.A.</option>
                      <option value="336">BANCO C6 S.A.</option>
                      <option value="033">BANCO SANTANDER S.A.</option>
                      <option value="151">BANCO NOSSA CAIXA S.A.</option>
                      <option value="745">BANCO CITIBANK S.A.</option>
                      <option value="041">
                        BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.
                      </option>
                      <option value="756">BANCO SICOOB</option>
                      <option value="748">BANCO SICREDI</option>
                      <option value="085">
                        COOPERATIVA CENTRAL DE CRÉDITO - AILOS
                      </option>
                      <option value="399">HSBC BANK BRASIL S.A</option>
                      <option value="756">
                        BANCOOB - BANCO COOPERATIVO DO BRASIL S.A.
                      </option>
                      <option value="041">BANRISUL</option>
                      <option value="212">BANCO ORIGINAL</option>
                      <option value="260">NU BANK PAGAMENTOS S.A.</option>
                      <option value="318">BANCO BMG S.A.</option>
                      <option value="336">BANCO C6 S.A. (C6 BANK)</option>
                      <option value="077">BANCO INTER S.A.</option>
                    </select>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group">
                    <label htmlFor="agency">Agência</label>
                    <input
                      id="agency"
                      name="agency"
                      className="form-control"
                      placeholder="Informe o nome da Indicação"
                      onChange={handleSetValue}
                      onBlur={handleSetValue}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group">
                    <label htmlFor="numberAccount">
                      Número da conta com digito
                    </label>
                    <input
                      id="numberAccount"
                      name="numberAccount"
                      className="form-control"
                      placeholder="Informe o nome da Indicação"
                      onChange={handleSetValue}
                      onBlur={handleSetValue}
                    />
                  </div>
                </Col>
                <Col xl="12">
                  <div className="form-group">
                    <label htmlFor="typeAccount">Tipo de conta</label>
                    <select
                      id="typeAccount"
                      name="typeAccount"
                      className="form-control"
                      onChange={handleSetValue}
                      onBlur={handleSetValue}
                    >
                      <option value="">SELECIONE</option>
                      <option value="1">CONTA CORRENTE</option>
                      <option value="2">POUPANÇA</option>
                    </select>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="mt-4">
            <h3>PIX</h3>
            <div className="bg-secondary p-3">
              <Row>
                <Col xl="12">
                  <div className="form-group">
                    <label htmlFor="pix">Chave PIX</label>
                    <input
                      id="pix"
                      name="pix"
                      className="form-control"
                      placeholder="Informe sua chave PIX, e-mail, telefone, cpf, cnpj ou aleatória"
                      onChange={handleSetValue}
                      onBlur={handleSetValue}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={isBlocked} onClick={handleSubmit}>
            SALVAR
          </Button>
          <Button color="secondary" onClick={closeModal}>
            SAIR
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RegisterAccessBank;
