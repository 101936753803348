import QrCodeComponent from 'components/Form/QrCodeComponent';
import Header from 'components/Headers/Header';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { updateLandingPage, getLandingPage } from 'services/User/LandingPage';
import Swal from 'sweetalert2';
import { phoneMask } from 'utils/mask';
import ImageHeaderComponenet from './Components/ImageHeaderComponent';
import InputFebrabanView from 'components/Form/Input/InputFebrabanView';

const LandingPageFGTSView = () => {
  const [form, setForm] = useState({
    pixel: null,
    tagManager: null,
    contactPhone: null,
    url: null,
  });

  const handleGetLandingPage = () => {
    Swal.close();
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getLandingPage('fgts').then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setForm(result);
      console.log(result.nameFebraban, result.preferenceBank);
    });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Salvando alteração',
      text: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const dataSend = {
      ...form,
    };
    updateLandingPage('fgts', dataSend)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Registro alterado com sucesso!',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  console.log(form);

  useEffect(() => {
    handleGetLandingPage();
  }, []);

  return (
    <>
      <Header showCards={false} />
      <section className="mt--8 container-fluid">
        <Card className="shadow mb-4">
          <CardHeader>
            <Row>
              <Col>
                <h3 className="mt-2">Landing Page FGTS - Personalizar</h3>
              </Col>
              <Col className="text-right">
                <a
                  href={form.url}
                  target="_blank"
                  className="btn btn-primary"
                  size="sm"
                >
                  <i className="fas fa-external-link-alt" />
                  {` `}
                  Ver Landing Page FGTS
                </a>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Alert color="warning">
              <h3 className="m-0 text-white">
                Atenção: A izidev não realiza configuração no píxel e
                tagmanager. Não nos responsábilizamos pelo trabalho realizado
                pela equipe de marketing ou terceiros.
              </h3>
            </Alert>
            <Row>
              <Col md="8">
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID Pixel</Label>
                      <Input
                        value={form.pixel}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            pixel: ev.target.value.replace(/[^0-9]/g, ''),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>ID TAG MANAGER</Label>
                      <Input
                        value={form.tagManager}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            tagManager: ev.target.value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label>Telefone Contato / Suporte</Label>
                      <Input
                        value={form.contactPhone}
                        onChange={(ev) => {
                          setForm({
                            ...form,
                            contactPhone: phoneMask(ev.target.value),
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <InputFebrabanView
                      nameLabel="Banco de preferência"
                      defaultValue={
                        form?.preferenceBank != ''
                          ? {
                            value: form?.preferenceBank,
                            label: form?.nameFebraban,
                          }
                          : null
                      }
                      setFilter={(e) => {
                        setForm({ ...form, preferenceBank: e.febraban, nameFebraban: e.nameBank });
                      }}
                      isClearInput={true}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <QrCodeComponent url={form.url} />
              </Col>
            </Row>
            <ImageHeaderComponenet
              landingPage={form}
              setLandingPage={setForm}
            />
          </CardBody>
          <CardFooter>
            <Button onClick={handleSubmit} color="warning">
              SALVAR ALTERAÇÃO
            </Button>
          </CardFooter>
        </Card>
      </section>
    </>
  );
};

export default LandingPageFGTSView;
