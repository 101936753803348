import { Alert, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import RuleTableSimulationComponent from '../RuleTableSimulationComponent';
import MyTablesComponent from '../myTablesComponent';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { listAllTablesService } from 'services/AccessBank/AccessBankService';

const AccessBankFintechDoCorbanForm = ({ febrabanId, data, setData }) => {
  const [tableAmmount, setTableAmmount] = useState([]);

  const handleListAllTables = async () => {
    try {
      const { data } = await listAllTablesService(febrabanId);
      setTableAmmount(data.resultSet ?? []);
    } catch (err) {
      const code = !err.response ? '00000' : err.response.data.code;
      const message = !err.response
        ? 'Ocorreu um erro inesperado'
        : err.response.data.message;
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `[${code}] ${message}`,
      });
    }
  };

  useEffect(() => {
    if (!febrabanId || febrabanId !== '329-7') return;

    handleListAllTables();
  }, []);

  if (!febrabanId || febrabanId !== '329-7') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas FGTS
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Acompanhamento de Propostas
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Atualização de status de proposta
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Link de formalização
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar - FGTS</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Tabela FGTS <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.tableAmmount}
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    tableAmmount: ev.target.value,
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>
                {tableAmmount.length > 0 ? (
                  tableAmmount.map((register, key) => (
                    <option value={register.tableId} key={key}>
                      {register.tableName}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Financeira Processamento <span className="text-danger">*</span>
              </Label>
              <select
                className="form-control"
                value={data.aditional}
                onChange={(ev) => {
                  setData({
                    ...data,
                    aditional: ev.target.value,
                  });
                }}
              >
                <option value="">Selecione...</option>
                <option value="qi">QI</option>
                <option value="j17">J17</option>
                <option value="bmp">BMP</option>
              </select>
            </FormGroup>
          </Col>
          {/* <Col md="6">
            <FormGroup>
              <Label>
                Valor Mínimo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="minValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.minValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    minValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada a
                partir do valor informado. Caso o valor liberado do FGTS esteja
                abaixo do valor informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Máximo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value)
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col> */}
          <Col md="6">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value,
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Simulador MultiBancos</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useMultBankFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useMultBankFgts: ev.target.value,
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
      </div>

      {/* <RuleTableSimulationComponent febrabanId={febrabanId} /> */}
      <MyTablesComponent febrabanId={febrabanId} />
    </CardBody>
  );
};

export default AccessBankFintechDoCorbanForm;
