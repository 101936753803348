import Header from 'components/Headers/Header';
import { TableHead } from 'components/Table';
import { TableRow } from 'components/Table';
import { TableHeader } from 'components/Table';
import { TableBody } from 'components/Table';
import { TableData } from 'components/Table';
import { Table } from 'components/Table';
import { CaretRight } from 'phosphor-react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { MobileTitle, ContentWrapper } from 'views/Dashboard/styles';

import { EnumService } from 'utils/EnumService';
import {
  ImportProposalService,
  listFilesImportedService,
} from 'services/Import/ImportProposalService';
import InputBankView from 'components/Form/Input/InputBankView';

const ImportProposalView = () => {
  const [form, setForm] = useState({
    febrabanId: '',
    serviceId: '',
  });
  const [file, setFile] = useState(null);
  const [result, setResult] = useState([]);
  const [tableDetails, setTableDetails] = useState(null);

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const upload = (file, appendData = [], onUploadProgress) => {
    let formData = new FormData();

    formData.append('upload', file);
    if (appendData.length > 0) {
      appendData.map((register) => {
        formData.append(register.name, register.code);
      });
    }

    return ImportProposalService(formData, onUploadProgress);
  };

  const handleUploadFile = () => {
    if (!form?.serviceId || !form?.febrabanId) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Existem campos obrigatórios que não foram preenchidos',
      });
      return;
    }

    Swal.fire({
      title: 'Enviando arquivo',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    upload(
      file,
      [
        { name: 'serviceId', code: form.serviceId },
        { name: 'febrabanId', code: form.febrabanId },
      ],
      () => {}
    )
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Arquivo enviado com sucesso, agora basta aguardar processamento da rotina!',
        }).then(() => {
          handleListImportedFiles();
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListImportedFiles = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    setResult([]);
    listFilesImportedService('PROPOSAL')
      .then((res) => {
        Swal.close();
        setResult(res?.data?.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleListImportedFiles();
  }, []);

  return (
    <>
      <Header titlePage="IMPORTAÇÃO - IMPORTAR PROPOSTAS" />
      <Container className="mt--9" fluid>
        <Card className="shadow">
          <CardHeader>
            <h2>
              <i className="fas fa-file-csv" /> IMPORTAÇÃO - IMPORTAR PROPOSTAS
            </h2>
          </CardHeader>
          <CardBody>
            <Alert color="secondary">
              <i className="far fa-question-circle" /> Modelo de planilha para
              importação -{' '}
              <a
                href="https://storage.googleapis.com/simulador-fgts/modelo/MODELO%20PLANILHA%20-%20IMPORTA%C3%87%C3%83O%20PROPOSTA%20V3.csv"
                download
              >
                <strong>BAIXAR</strong>
              </a>
            </Alert>
            <FormGroup>
              <Label>
                Arquivo CSV <Badge color="info">Tamanho máximo 1MB</Badge>{' '}
                <Badge color="warning">Obrigatório</Badge>
              </Label>
              <Input
                type="file"
                accept=".csv"
                className="form-control"
                onChange={(ev) => {
                  const file = ev.target.files[0];

                  if (!file) return;

                  const sizeFile = file.size / 1024 / 1024;

                  if (sizeFile > 1) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Error',
                      text: 'O arquivo não pode ser importado. Por favor, reduza a quantidade de registros e importe novamente!',
                    });
                    return;
                  }

                  setFile(file);
                }}
              />
            </FormGroup>
            <Row>
              <Col md="6">
                <InputBankView
                  allBanks={1}
                  placeholder="Selecione..."
                  defaultValue={{
                    value: form.febrabanId,
                  }}
                  filter={form}
                  setFilter={(e) => {
                    setForm({
                      ...form,
                      febrabanId: e.febrabanId,
                      bankId: e.bankId,
                    });
                  }}
                  isClearable
                />
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label>
                    Serviço / Produto <Badge color="warning">Obrigatório</Badge>
                  </Label>
                  <select
                    className="form-control"
                    value={form.serviceId}
                    onChange={(ev) => {
                      setForm({
                        ...form,
                        serviceId: ev.target.value,
                      });
                    }}
                  >
                    <option value="" disabled>
                      Selecione...
                    </option>
                    {EnumService.map((bank, key) => (
                      <option value={bank.code} key={key}>
                        {bank.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter>
            <Button color="success" onClick={handleUploadFile}>
              <i className="fas fa-file-import" /> Importar
            </Button>
          </CardFooter>
        </Card>

        {result.length > 0 && (
          <Card className="mt-3">
            <CardHeader>Últimas Importações</CardHeader>
            <CardBody className="p-2 m-0">
              <Alert color="secondary">
                <h1>
                  <i className="fas fa-exclamation-circle" /> Atenção
                </h1>
                <p>
                  - Os arquivos importados ficarão disponíveis por 1(um) dia.
                  Após esse prazo, por segurança, serão excluídos,
                  automaticamente, pelo sistema.
                </p>
                <p>
                  - Realize a importação no período das{' '}
                  <strong>07h às 18h</strong>.
                </p>
              </Alert>
            </CardBody>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>DATA IMPORTAÇÃO</TableHead>
                  <TableHead>DETALHES DA IMPORTAÇÃO</TableHead>
                  <TableHead className="d-none d-lg-table-cell">
                    ARQUIVO
                  </TableHead>
                  <TableHead className="d-none d-lg-table-cell" />
                  <TableHead className="d-xl-none" />
                </TableRow>
              </TableHeader>
              <TableBody>
                {result.map((register, key) => (
                  <>
                    <TableRow key={key}>
                      <TableData>{register.createdAt}</TableData>
                      <TableData>
                        <p className="m-0">
                          Status: <strong>{register.status}</strong>
                        </p>
                        <p className="m-0">
                          Data atualização: <strong>{register.updateAt}</strong>
                        </p>
                      </TableData>
                      <TableData className="d-none d-xl-table-cell">
                        <a
                          href={register.url}
                          className="btn btn-secondary"
                          target="blank"
                        >
                          <i className="fas fa-link" /> Arquivo Importado
                        </a>
                      </TableData>
                      <TableData className="d-xl-none">
                        <CaretRight
                          active={tableDetails === key}
                          onClick={() => handleTableDetails(key)}
                        />
                      </TableData>
                    </TableRow>
                    {tableDetails === key && (
                      <TableRow className="d-xl-none">
                        <TableData colSpan={10}>
                          <ContentWrapper className="d-lg-none">
                            <MobileTitle>Data Atualização</MobileTitle>
                            {register.updateAt}
                          </ContentWrapper>
                          <ContentWrapper>
                            <a
                              href={register.url}
                              className="btn btn-secondary"
                              target="blank"
                            >
                              <i className="fas fa-link" /> Arquivo Importado
                            </a>
                          </ContentWrapper>
                        </TableData>
                      </TableRow>
                    )}
                  </>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}
      </Container>
    </>
  );
};

export default ImportProposalView;
