import {
  Alert,
  Badge,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { moneyMask, maskCPF } from 'utils/mask';

import RuleTableSimulationComponent from '../RuleTableSimulationComponent';
import MyTablesComponent from '../myTablesComponent';
import { listAllTablesService } from 'services/AccessBank/AccessBankService';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';

const AccessBankFactaForm = ({ febrabanId, data, setData }) => {
  const [tables, setTables] = useState([]);

  const handleList = () => {
    listAllTablesService(febrabanId, {
      serviceId: 2,
    })
      .then(({ data }) => {
        setTables(data.resultSet ?? []);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (febrabanId !== '149') return;

    handleList();
  }, []);

  if (!febrabanId || febrabanId !== '149') return <></>;

  return (
    <CardBody>
      <div>
        <Label for="status">
          Status <span className="text-danger">*</span>
        </Label>
        <div>
          <Label className="switch">
            <Input
              id="status"
              type="checkbox"
              checked={data.status == 1}
              onChange={(ev) => {
                setData({
                  ...data,
                  status: ev.target.checked,
                });
              }}
            />
            <span className="slider round" />
          </Label>
        </div>
      </div>
      <Alert color="secondary shadow">
        <h3>Funcionalidades</h3>
        <p className="m-0">
          <i className="fas fa-check" /> Digitação de propostas FGTS
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Acompanhamento de Propostas
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Atualização de status de proposta
        </p>
        <p className="m-0">
          <i className="fas fa-check" /> Link de formalização
        </p>
      </Alert>
      <div className="bg-secondary border p-4 rounded">
        <h3>Dados de acesso</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Login de API <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank"
                name="accessBank"
                type="text"
                autoComplete="false"
                value={data.login}
                className=""
                onChange={(ev) => {
                  setData({
                    ...data,
                    login: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Senha de API <span className="text-danger">*</span>
              </Label>
              <Input
                id="accessBank-b"
                name="accessBank-b"
                type="text"
                autoComplete="false"
                value={data.password}
                onChange={(ev) => {
                  setData({
                    ...data,
                    password: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Dados do usuário digitador - Certificado</h3>
        <Row>
          <Col md="3">
            <FormGroup>
              <Label>
                CPF <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.documentUser}
                placeholder="Ex: 000.000.000-00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    documentUser: maskCPF(ev.target.value),
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Label>
                Código Usuário Digitador <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.idBankUser}
                placeholder="Ex: 123456"
                onChange={(ev) => {
                  setData({
                    ...data,
                    idBankUser: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Código master{' '}
                <Badge color="warning">Informar apenas se for necessário</Badge>
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.aditional}
                placeholder="Ex: 123456"
                onChange={(ev) => {
                  setData({
                    ...data,
                    aditional: ev.target.value,
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <Alert color="warning" className="mt-4">
        <i className="fas fa-exclamation-triangle" /> <strong>Atenção:</strong>{' '}
        A FACTA Possui limitação de 2.000 simulações dia para o produto FGTS.
        Para maiores informações entre em contato com seu gerente comercial
        FACTA!
      </Alert>
      <Alert color="warning" className="mt-4">
        <i className="fas fa-exclamation-triangle" /> <strong>Atenção:</strong>{' '}
        Caso seja selecionada a <strong>tabela Smart</strong> e a mesma não
        permitir realizar a simulação, será necessário solicitar ao Facta a
        liberação desta tabela em sua API.
      </Alert>
      <div className="bg-secondary border p-4 rounded mt-4">
        <h3>Configurar Tabela de Crédito</h3>
        <Row>
          <Col md="6">
            <FormGroup>
              <Label>
                Tabela FGTS <span className="text-danger">*</span>
              </Label>
              <select
                type="text"
                autoComplete="false"
                value={data.tableAmmount}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    tableAmmount: ev.target.value,
                  });
                }}
              >
                <option value="" selected disabled>
                  Selecione...
                </option>
                {tables.map((register, key) => (
                  <option key={key} value={register.tableId}>
                    {register.tableName}
                  </option>
                ))}
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Mínimo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="minValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.minValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    minValueLibertyFgts: moneyMask(ev.target.value),
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="minValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada a
                partir do valor informado. Caso o valor liberado do FGTS esteja
                abaixo do valor informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>
                Valor Máximo liberado FGTS{' '}
                <i
                  className="fas fa-question-circle"
                  id="maxValueLibertyFgts"
                />
              </Label>
              <Input
                type="text"
                autoComplete="false"
                value={data.maxValueLibertyFgts}
                placeholder="Ex: 0,00"
                onChange={(ev) => {
                  setData({
                    ...data,
                    maxValueLibertyFgts: moneyMask(ev.target.value),
                  });
                }}
              />
              <UncontrolledTooltip delay={0} target="maxValueLibertyFgts">
                Se for preenchido este campo, a simulação será realizada até o
                valor informado. Caso o valor liberado do FGTS ultrapasse o
                informado, gerará erro!
              </UncontrolledTooltip>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Consulta FGTS</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useFgts: ev.target.value,
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label>Ativar Simulador MultiBancos</Label>
              <select
                type="text"
                autoComplete="false"
                value={data.useMultBankFgts}
                placeholder="Ex: 000.000.000-00"
                className="form-control"
                onChange={(ev) => {
                  setData({
                    ...data,
                    useMultBankFgts: ev.target.value,
                  });
                }}
              >
                <option value="1">SIM</option>
                <option value="0">NÃO</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
        {data.tableAmmount === '40797' && (
          <div className="text-danger">
            <strong className="font-weight-bold">Atenção: </strong> Caso haja
            erro ao simular com a tabela{' '}
            <strong className="font-weight-bold">
              40797 - FGTS SMART / PREMIUM
            </strong>{' '}
            será necessário solicitar ao seu comercial Facta a liberação da
            mesma na API.
          </div>
        )}
      </div>

      <RuleTableSimulationComponent febrabanId={febrabanId} />
      <MyTablesComponent febrabanId={febrabanId} />
    </CardBody>
  );
};

export default AccessBankFactaForm;
