import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { listReport } from 'services/Report/Schedule';
import Swal from 'sweetalert2';

const ReportSchedule = () => {
  const [listData, setListData] = useState([]);

  const getDataReport = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    listReport()
      .then((result) => {
        Swal.close();
        setListData(result.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    getDataReport();

    setInterval(() => {
      getDataReport();
    }, 180000);
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Relatórios em CSV</h3>
                  </div>
                  <div className="col text-right" />
                </Row>
              </CardHeader>
              {listData.length > 0 ? (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">DATA SOLICITAÇÃO</th>
                      <th scope="col">USUÁRIO</th>
                      <th scope="col">RELATÓRIO</th>
                      <th scope="col">TIPO</th>
                      <th scope="col">STATUS</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {listData.map((register, key) => (
                      <>
                        <tr key={key}>
                          <td>{register.createdAt}</td>
                          <td>{register.userName}</td>
                          <td>{register.name}</td>
                          <td>{register.type}</td>
                          <td>
                            {register.url
                              ? 'FINALIZADO'
                              : 'AGUARDANDO PROCESSAMENTO'}
                          </td>
                          <td>
                            {register.url ? (
                              <a
                                href={register.url}
                                className="btn btn-success btn-md"
                                download
                              >
                                BAIXAR RELATÓRIO
                              </a>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <Alert color="secondary" className="text-center">
                  Não possuem solicitações de relatório no momento
                </Alert>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ReportSchedule;
