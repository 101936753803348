import { useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Tooltip,
} from 'reactstrap';
import { moneyMask, maskCPF, phoneMask } from 'utils/mask';
import { BankSelectData } from 'views/AccessBank/service/bankSelectData';
import { WhatsAppLink } from 'views/Dashboard/styles';

const CardReportDigitationComponent = ({
  toggleShowList = 1,
  listData = [],
  getLinkFormalize = () => { },
  setTagDataModal = () => { },
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({});

  const handleSelectColorStatus = (status) => {
    switch (status) {
      case 'SIMULAÇÃO NÃO FINALIZADA':
        return 'warning';
      case 'DIGITAÇÃO REALIZADA':
        return 'success';
      default:
        return 'primary';
    }
  };

  const toggleTooltip = (id) => {
    setTooltipOpen((prevTooltipOpen) => ({
      ...prevTooltipOpen,
      [id]: !prevTooltipOpen[id],
    }));
  };

  if (toggleShowList > 0) {
    return null;
  }

  return (
    <Card className="bg-transparent border-0">
      {listData.length > 0 && (
        <Row className="mt-4 mx-2">
          {listData.map((register, index) => {
            const uniqueIndex = `${index}-${register.proposalId}`;
            const statusBankTooltipId = `statusBankTooltipId-${uniqueIndex}`;
            const febrabanTooltipId = `febrabanTooltipId-${uniqueIndex}`;
            const nameTooltipId = `nameTooltipId-${uniqueIndex}`;
            const proposalTooltipId = `proposalTooltipId-${uniqueIndex}`;
            const codeIndicationTooltipId = `codeIndicationTooltipId-${uniqueIndex}`;
            const statusTooltip = `statusTooltip-${uniqueIndex}`;
            const nameIndicationTooltip = `nameIndicationTooltip-${uniqueIndex}`;

            return (
              <Col
                key={uniqueIndex}
                md="4"
                sm="6"
                className="d-flex align-items-stretch px-1"
              >
                <Card className="shadow mb-4 w-100">
                  <CardBody>
                    {register?.messageReturnService && (
                      <Alert color="warning">
                        {register?.messageReturnService}
                      </Alert>
                    )}
                    <div className="mb-2">
                      <Badge
                        id={statusTooltip}
                        className="font-12 mt-2 w-100 overflow-none"
                        color={handleSelectColorStatus(register.status)}
                        onMouseEnter={() => toggleTooltip(statusTooltip)}
                        onMouseLeave={() => toggleTooltip(statusTooltip)}
                      >
                        {register.status}
                      </Badge>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[statusTooltip] || false}
                        target={statusTooltip}
                        toggle={() => toggleTooltip(statusTooltip)}
                      >
                        {register.status}
                      </Tooltip>
                      <Badge
                        id={statusBankTooltipId}
                        className="font-12 mt-2 w-100 overflow-none"
                        color="secondary"
                        onMouseEnter={() => toggleTooltip(statusBankTooltipId)}
                        onMouseLeave={() => toggleTooltip(statusBankTooltipId)}
                      >
                        {register.statusBank}
                      </Badge>
                      <Tooltip
                        placement="top"
                        isOpen={tooltipOpen[statusBankTooltipId] || false}
                        target={statusBankTooltipId}
                        toggle={() => toggleTooltip(statusBankTooltipId)}
                      >
                        {register.statusBank}
                      </Tooltip>
                    </div>
                    {register.febrabanId !== '999' && (
                      <>
                        {BankSelectData(register.febrabanId)?.img && (
                          <img
                            src={BankSelectData(register.febrabanId).img}
                            alt={register.nameBank}
                            width="40%"
                          />
                        )}
                        <p
                          className="m-0 overflow-none"
                          id={febrabanTooltipId}
                          onMouseEnter={() => toggleTooltip(febrabanTooltipId)}
                          onMouseLeave={() => toggleTooltip(febrabanTooltipId)}
                        >
                          <strong>{register.bank}</strong>
                        </p>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[febrabanTooltipId] || false}
                          target={febrabanTooltipId}
                          toggle={() => toggleTooltip(febrabanTooltipId)}
                        >
                          {register.bank}
                        </Tooltip>
                      </>
                    )}
                    <h3
                      className="mt-3 mb-0 overflow-none"
                      color="secondary"
                      id={nameTooltipId}
                      onMouseEnter={() => toggleTooltip(nameTooltipId)}
                      onMouseLeave={() => toggleTooltip(nameTooltipId)}
                    >
                      {register.name}
                    </h3>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[nameTooltipId] || false}
                      target={nameTooltipId}
                      toggle={() => toggleTooltip(nameTooltipId)}
                    >
                      {register.name}
                    </Tooltip>
                    {register.numberProposal && (
                      <>
                        <p
                          className="m-0 overflow-none"
                          id={proposalTooltipId}
                          onMouseEnter={() => toggleTooltip(proposalTooltipId)}
                          onMouseLeave={() => toggleTooltip(proposalTooltipId)}
                        >
                          Nº Contrato:{' '}
                          <strong>{register.numberProposal}</strong>
                        </p>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[proposalTooltipId] || false}
                          target={proposalTooltipId}
                          toggle={() => toggleTooltip(proposalTooltipId)}
                        >
                          {register.numberProposal}
                        </Tooltip>
                      </>
                    )}
                    <p className="m-0">
                      Vlr Liquido:{' '}
                      <strong>
                        R$ {moneyMask(register?.valueLiberty ?? 0.0)}
                      </strong>
                    </p>
                    <p className="m-0">
                      Vlr Bruto:{' '}
                      <strong>
                        R$ {moneyMask(register?.valueFinally ?? 0.0)}
                      </strong>
                    </p>
                    <p className="m-0">
                      CPF: <strong>{maskCPF(register.cpf)}</strong>
                    </p>
                    <p className="m-0">
                      WhatsApp:{' '}
                      <strong>
                        {phoneMask(register.whatsappNumber)}{' '}
                        <WhatsAppLink
                          className="btn pl-1 pr-1 btn-success"
                          href={register.urlWhatsapp}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-whatsapp" />
                        </WhatsAppLink>
                      </strong>
                    </p>
                    <p
                      className="m-0 overflow-none"
                      id={`activity-${register.id}-${index}`}
                      onMouseEnter={() =>
                        toggleTooltip(`activity-${register.id}-${index}`)
                      }
                      onMouseLeave={() =>
                        toggleTooltip(`activity-${register.id}-${index}`)
                      }
                    >
                      Atividade:{' '}
                      <strong className="font-weight-bold">
                        {register.activity ?? '---'}
                      </strong>
                      <Tooltip
                        placement="top"
                        isOpen={
                          tooltipOpen[`activity-${register.id}-${index}`] ||
                          false
                        }
                        target={`activity-${register.id}-${index}`}
                        toggle={() =>
                          toggleTooltip(`activity-${register.id}-${index}`)
                        }
                      >
                        {register.activity ?? '---'}
                      </Tooltip>
                    </p>
                    <p className="m-0">
                      Data simulação: <strong>{register.createdAt}</strong>
                    </p>
                    {register?.codeIndication && (
                      <Alert color="success" className="mt-2 text-white">
                        <h4 className="m-0">Indicação / Vendedor</h4>
                        <p
                          className="m-0 font-12 overflow-none"
                          id={codeIndicationTooltipId}
                          onMouseEnter={() =>
                            toggleTooltip(codeIndicationTooltipId)
                          }
                          onMouseLeave={() =>
                            toggleTooltip(codeIndicationTooltipId)
                          }
                        >
                          Código: <strong>{register.codeIndication}</strong>
                        </p>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[codeIndicationTooltipId] || false}
                          target={codeIndicationTooltipId}
                          toggle={() => toggleTooltip(codeIndicationTooltipId)}
                        >
                          {register.codeIndication}
                        </Tooltip>
                        <p
                          className="m-0 font-12 overflow-none"
                          id={nameIndicationTooltip}
                          onMouseEnter={() =>
                            toggleTooltip(nameIndicationTooltip)
                          }
                          onMouseLeave={() =>
                            toggleTooltip(nameIndicationTooltip)
                          }
                        >
                          Nome:{' '}
                          <strong className="font-weight-bold">
                            {register.nameIndication}
                          </strong>
                        </p>
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[nameIndicationTooltip] || false}
                          target={nameIndicationTooltip}
                          toggle={() => toggleTooltip(nameIndicationTooltip)}
                        >
                          {register.nameIndication}
                        </Tooltip>
                      </Alert>
                    )}
                    <div className="mt-3">
                      <a
                        className="btn btn-none mr-0"
                        id={`detailsTooltip-${register.id}-${index}`}
                        href={`/admin/proposal-details/${register.proposalId ?? 0
                          }`}
                        target="_blank"
                      >
                        <i className="fas fa-tag" />
                        <Tooltip
                          placement="top"
                          isOpen={
                            tooltipOpen[
                            `detailsTooltip-${register.id}-${index}`
                            ] || false
                          }
                          target={`detailsTooltip-${register.id}-${index}`}
                          toggle={() =>
                            toggleTooltip(
                              `detailsTooltip-${register.id}-${index}`
                            )
                          }
                        >
                          Detalhes do Contrato
                        </Tooltip>
                      </a>

                      <Button
                        color="none"
                        className="mr-0 text-success"
                        id={`formalizationLinkTooltip-${register.id}-${index}`}
                        onClick={() => {
                          getLinkFormalize(
                            register.numberProposal,
                            register.febrabanId
                          );
                        }}
                      >
                        <i className="fas fa-link" />
                        <Tooltip
                          placement="top"
                          isOpen={
                            tooltipOpen[
                            `formalizationLinkTooltip-${register.id}-${index}`
                            ] || false
                          }
                          target={`formalizationLinkTooltip-${register.id}-${index}`}
                          toggle={() =>
                            toggleTooltip(
                              `formalizationLinkTooltip-${register.id}-${index}`
                            )
                          }
                        >
                          Link de formalização
                        </Tooltip>
                      </Button>

                      <Button
                        color="none"
                        className="mr-0"
                        id={`tagsTooltip-${register.id}`}
                        onClick={() => {
                          setTagDataModal({
                            isOpen: true,
                            clientId: register.clientId,
                          });
                        }}
                      >
                        <i className="fas fa-tags" />
                        <Tooltip
                          placement="top"
                          isOpen={
                            tooltipOpen[`tagsTooltip-${register.id}`] || false
                          }
                          target={`tagsTooltip-${register.id}`}
                          toggle={() =>
                            toggleTooltip(`tagsTooltip-${register.id}`)
                          }
                        >
                          Etiquetas
                        </Tooltip>
                      </Button>

                    </div>
                    {register?.tags?.length > 0 && (
                      <div className="mt-3">
                        {register.tags.map((tag, tagKey) => (
                          <Badge
                            key={tagKey}
                            className={`${tag.color} mr-1 mb-1 font-12 shadow`}
                          >
                            {tag.title}
                          </Badge>
                        ))}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
    </Card>
  );
};

export default CardReportDigitationComponent;
