import { useEffect } from 'react';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import { Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';

const ViewLinkAndQrCode = ({ url }) => {
  const [, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    Swal.fire({
      icon: 'success',
      title: 'Sucesso',
      text: 'URL Copiada com sucesso',
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      <Row>
        <Col sm="12">
          <div>
            <p className="m-0">URL</p>
            <div className="input-group">
              <input
                className="form-control from-control-md"
                type="text"
                value={url}
                readOnly={true}
              
              />
              <div className="input-group-append">
                <CopyToClipboard text={url} onCopy={onCopyText}>
                  <button className="btn btn-success btn-md">Copiar</button>
                </CopyToClipboard>
              </div>
            </div>

            <p className='mt-3'>QRCode</p>
            <QRCode value={url} size="200" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ViewLinkAndQrCode;
