import SampleModalView from 'components/Modals/SampleModalView';
import { useState } from 'react';
import { Badge, Button, Card, Col, Row, Table } from 'reactstrap';
import { moneyMask } from 'utils/mask';

const TableResultSimulationComponent = ({
  table,
  handleSelectSimulation = () => { },
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Row>
        <Col md="12">
          <p className="m-0">Tabela banco:</p>
          <p className="m-0 font-weight-bold mb-2 font-20">
            {table.name}{' '}
            {table?.useSafe > 0 && <Badge color="success">COM SEGURO</Badge>}
          </p>
          <p className="m-0">
            Parcelas:{' '}
            <strong className="font-weight-bold">
              {table?.qtdInstallment && table?.qtdInstallment > 0
                ? table.qtdInstallment
                : table.installments.length}
            </strong>
          </p>
          <p className="m-0">
            Vlr. Total:{' '}
            <strong className="font-weight-bold">
              R$ {table?.valueFinally?.toFixed(2)}
            </strong>
          </p>
          <p className="m-0 font-24">
            Vlr. Liberado:{' '}
            <strong className="font-weight-bold text-success">
              R$ {table?.valueLiberty?.toFixed(2)}
            </strong>
          </p>
          <div className="p-3 mb-2 rounded bg-secondary">
            <p className="m-0 font-16">
              % Comissinamento:{' '}
              <strong className="font-weight-bold">
                {table?.comission ? table?.comission : 'Não informado'}
              </strong>
            </p>
            <p className="m-0 font-16">
              Comissionamento:{' '}
              <strong className="font-weight-bold text-success">
                {table?.valueComission
                  ? `R$ ${moneyMask(table.valueComission.toFixed(2))}`
                  : 'Não informado'}
              </strong>
            </p>
          </div>
        </Col>
        {table?.installments?.length > 0 && (
          <Col md="12">
            <Button
              color="primary"
              className="w-100 mt-2 mb-2"
              onClick={() => setShowModal(true)}
            >
              <i className="fas fa-list-ol" /> Parcelas
            </Button>
          </Col>
        )}
        <Col md="12">
          <Button
            color="success"
            className="w-100 mt-2 mb-2"
            onClick={handleSelectSimulation}
          >
            <i className="fas fa-check" /> Selecionar
          </Button>
        </Col>
      </Row>

      {table?.installments?.length && (
        <SampleModalView
          labelModal={`Parcelas ref. tabela: ${table.name}`}
          icon="fas fa-list-ol"
          isOpen={showModal}
          setIsOpen={setShowModal}
          showBtnSubmit={false}
          size="lg"
        >
          <Card className="shadow">
            <Table>
              <thead className="bg-secondary">
                <tr>
                  <th>Parcela</th>
                  <th>Vencimento</th>
                  <th className="text-right">Valor</th>
                </tr>
              </thead>
              <tbody>
                {table.installments.map((installment, key) => (
                  <tr key={`installment-${key}`}>
                    <td>{key + 1}</td>
                    <td>{installment.date}</td>
                      <td className="text-right font-weight-bold font-14">
                        R$ {moneyMask(Number(installment.amount).toFixed(2))}
                      </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </SampleModalView>
      )}
    </>
  );
};

export default TableResultSimulationComponent;
