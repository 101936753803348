import { useEffect, useState } from 'react';
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  CardBody,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Button,
  Table,
  Alert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Header from 'components/Headers/Header';
import Swal from 'sweetalert2';
import { phoneMask, maskCPF, maskDate, cepMask, moneyMask } from 'utils/mask';
import Address from './component/address';
import BankDigitation from './component/bankDigitation';
import {
  digitationService,
  getDigitationServiceByUuid,
  getDigitationHistoryServiceByUuid,
  showLinkFormalize,
} from 'services/Digitation/DigitationService';
import { useParams, useHistory } from 'react-router-dom';
import { getAllNoteByClientId } from 'services/User/Note';
import InputBankView from 'components/Form/Input/InputBankView';
import SelectServiceView from 'components/Form/Input/SelectServiceView';
import SelectTypeServiceView from 'components/Form/Input/SelectTypeServiceView';
import SelectProductView from 'components/Form/Input/SelectProductView';
import SelectTableBankView from 'components/Form/Input/SelectTableBankView';

import getBase64 from 'utils/input-base64';

const DigitationINSS = () => {
  let { id } = useParams();
  const history = useHistory();
  const defaultValud = {
    cpf: null,
    name: null,
    birthDate: null,
    phone: null,
    email: null,
    registry: null,
    documentRG: null,
    dateEmissionRG: null,
    organEmissionRg: null,
    stateEmissionRg: null,
    sex: null,
    stateCivil: null,
    nameMother: null,
    cep: null,
    address: null,
    numberAddress: null,
    district: null,
    city: null,
    state: null,
    bank: null,
    agency: null,
    numberAccount: null,
    typeAccount: null,
    pix: null,
    marginIncrease: 0,
  };
  const [digitationHistory, setDigitationHistory] = useState([]);
  const [clientNote, setClientNote] = useState([]);
  const [digitation, setDigitation] = useState(defaultValud);

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Salvando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    digitationService({
      id: digitation.id,
      uuid: digitation.uuid,
      name: digitation.name,
      cpf: digitation.cpf,
      birthDate: digitation.birthDate,
      email: digitation.email,
      whatsappNumber: digitation.phone,
      documentRG: digitation.documentRG,
      dateEmissionRG: digitation.dateEmissionRG,
      organRg: digitation.organEmissionRg,
      stateRG: digitation.stateEmissionRg,
      sex: digitation.sex,
      cep: digitation.cep,
      address: digitation.address,
      district: digitation.district,
      number: digitation.numberAddress,
      city: digitation.city,
      state: digitation.state,
      registry: digitation.registry,
      pix: digitation.pix,
      bank: digitation.bank,
      numberAccount: digitation.numberAccount,
      agency: digitation.agency,
      typeAccount: digitation.typeAccount,
      simulationInss: digitation.simulationInss,
      febrabanId: digitation.febrabanId,
      nameMother: digitation.nameMother,
      stateCivil: digitation.stateCivil,
      observation: digitation.observation,
      marginIncrease: digitation.marginIncrease,
      bankId: digitation.bankId,
      valueLiberty: digitation.valueLiberty,
      valueInstallment: digitation.valueInstallment,
      tableComissionBankId: digitation.tableComissionBankId,
      serviceId: digitation.serviceId,
      installment: digitation.installment,
      numberProposal: digitation.numberProposal,
      RG_FRONT: digitation.RG_FRONT,
      RG_BACK: digitation.RG_BACK,
      CPF_FRONT: digitation.CPF_FRONT,
      CPF_BACK: digitation.CPF_BACK,
      DOCUMENT_BANK: digitation.DOCUMENT_BANK,
      DOCUMENT_ADDRESS: digitation.DOCUMENT_ADDRESS,
    })
      .then(() => {
        if (digitation.id > 0) {
          Swal.fire({
            icon: 'success',
            title: 'Successo',
            text: 'Digitação alterada com sucesso!',
          });
          return;
        }

        Swal.fire({
          icon: 'success',
          title: 'Successo',
          text: 'Digitação salva com sucesso!',
          showCancelButton: true,
          cancelButtonText: 'Sair',
          confirmButtonText: 'Nova digitação',
        }).then((confirm) => {
          if (!confirm.isConfirmed) {
            history.push('/admin/proposals');
            return;
          }

          setDigitation(defaultValud);
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleGetDigitation = (uuid) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getDigitationServiceByUuid(uuid).then((res) => {
      Swal.close();
      const result = res.data.resultSet;
      setDigitation({
        id: result.id,
        name: result.name,
        uuid: result.uuid,
        cpf: maskCPF(result.documentCPF),
        birthDate: result.birthDate,
        email: result.email,
        phone: phoneMask(result.whatsappNumber),
        documentRG: result.documentRG,
        dateEmissionRG: result.dateEmissionRG,
        organEmissionRg: result.emissionRG,
        stateEmissionRg: result.stateRG,
        sex: result.sex,
        cep: result.CEP ? cepMask(result.CEP) : '',
        address: result.address,
        district: result.district,
        numberAddress: result.number,
        city: result.city,
        state: result.state,
        registry: result.registry,
        pix: result.pix,
        bank: result.bank,
        numberAccount: result.numberAccount,
        agency: result.agency,
        typeAccount: result.typeAccount,
        simulationInss: result.simulationInss,
        febrabanId: result.febrabanId,
        nameMother: result.nameMother,
        stateCivil: result.stateCivil,
        complement: result.complement,
        clientId: result.clientId,
        indicationId: result.indicationId,
        indication: result.indication,
        serviceId: result.serviceId,
        statusAttendantId: result.statusAttendantId,
        bankId: result.bankId,
        valueInstallment: moneyMask(
          result.valueInstallment > 0 ? result.valueInstallment.toFixed(2) : 0
        ),
        installment: result.installment,
        valueLiberty: moneyMask(
          result.valueLiberty > 0 ? result.valueLiberty.toFixed(2) : 0
        ),
        valueIncome: moneyMask(
          result.valueIncome > 0 ? result.valueIncome.toFixed(2) : 0
        ),
        tableComissionBankId: result.tableComissionBankId,
        typeServiceId: result.typeServiceId,
        productServiceId: result.productServiceId,
        numberProposal: result.numberProposal,
        documents: result.documents,
      });

      handleShowObservations(result.clientId);
    });
  };

  const handleShowHistory = (id) => {
    getDigitationHistoryServiceByUuid(id).then((res) => {
      setDigitationHistory(res.data.resultSet);
    });
  };

  const handleShowObservations = (clientId) => {
    getAllNoteByClientId(clientId).then((res) => {
      setClientNote(res.data.resultSet);
    });
  };

  const getLinkFormalize = async (numberProposal, febrabanId) => {
    Swal.fire({
      title: 'Buscando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showLinkFormalize(numberProposal, febrabanId)
      .then((res) => {
        const link = res.data.resultSet;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `<input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    if (!digitation.cpf && id) {
      handleGetDigitation(id);
      handleShowHistory(id);
    }
  }, [digitation]);

  if (id && !digitation?.id) {
    return (
      <>
        <Alert color="secondary">Registro não identificado</Alert>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h2 className="mb-0">
                  <i className="fas fa-file-alt" />{' '}
                  {digitation.id > 0 ? 'Editar' : 'Cadastrar'} Proposta
                  <Button
                    color="warning"
                    size="sm"
                    className="float-right"
                    onClick={() => {
                      history.push('/admin/proposals');
                    }}
                  >
                    <i className="fas fa-chevron-left" /> Voltar
                  </Button>
                </h2>
              </CardHeader>
              <CardBody>
                <Card className="shadow mb-3">
                  <CardHeader>
                    <h3 className="m-0">
                      <i className="fas fa-file-contract" /> Informações do
                      contrato
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="3">
                        <InputBankView
                          allBanks={1}
                          placeholder="Selecione..."
                          defaultValue={
                            digitation.bankId > 0
                              ? {
                                  value: digitation.bankId,
                                  label: '',
                                }
                              : null
                          }
                          filter={digitation}
                          setFilter={setDigitation}
                          isClearable
                        />
                      </Col>
                      <Col md="3">
                        <SelectServiceView
                          placeholder="Selecione..."
                          defaultValue={
                            digitation.serviceId > 0
                              ? {
                                  value: digitation.serviceId,
                                  label: '',
                                }
                              : null
                          }
                          filter={digitation}
                          setFilter={setDigitation}
                          isClearable
                        />
                      </Col>
                      <Col lg="3" md="6">
                        <SelectTypeServiceView
                          defaultValue={
                            digitation.typeServiceId > 0
                              ? {
                                  value: digitation.typeServiceId,
                                  label: '',
                                }
                              : null
                          }
                          filter={digitation}
                          setFilter={setDigitation}
                          isClearable
                        />
                      </Col>
                      <Col md="3">
                        <SelectProductView
                          defaultValue={
                            digitation.productServiceId > 0
                              ? {
                                  value: digitation.productServiceId,
                                  label: '',
                                }
                              : null
                          }
                          filter={digitation}
                          setFilter={setDigitation}
                          typeId={digitation.typeServiceId}
                          serviceId={digitation.serviceId}
                          febrabanId={digitation.febrabanId}
                          bankId={digitation.bankId}
                        />
                      </Col>
                      <Col md="6">
                        <SelectTableBankView
                          defaultValue={
                            digitation.tableComissionBankId > 0
                              ? {
                                  value: digitation.tableComissionBankId,
                                  label: '',
                                }
                              : null
                          }
                          filter={digitation}
                          setFilter={(e) => {
                            setDigitation({
                              ...digitation,
                              tableComissionBankId: e.tableBankId,
                            });
                          }}
                          productId={digitation.productServiceId}
                          febrabanId={digitation.febrabanId}
                        />
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label>Prazo</Label>
                          <Input
                            name="installment"
                            id="installment"
                            value={digitation.installment}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                installment: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label>Valor Parcela</Label>
                          <Input
                            name="valueFinally"
                            id="valueFinally"
                            value={digitation.valueInstallment}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                valueInstallment: moneyMask(ev.target.value),
                              });
                            }}
                            placeholder="0,00"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup>
                          <Label>Valor Cliente</Label>
                          <Input
                            name="valueLiberty"
                            id="valueLiberty"
                            value={digitation.valueLiberty}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                valueLiberty: moneyMask(ev.target.value),
                              });
                            }}
                            placeholder="0,00"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Nº da proposta</Label>
                          <Input
                            name="numberProposal"
                            id="numberProposal"
                            value={digitation.numberProposal}
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                numberProposal: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {Number(digitation.serviceId) === 6 &&
                  digitation.valueLiberty > 0 && (
                    <div className="p-3 bg-secondary mb-4">
                      <h4>Simulação</h4>
                      <Row>
                        <Col md="4">
                          <Card className="shadow">
                            <CardBody>
                              <h5 className="m-0">Parcelas</h5>
                              <p className="m-0">{digitation.installment}</p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="4">
                          <Card className="shadow">
                            <CardBody>
                              <h5 className="m-0">Valor Parcela</h5>
                              <p className="m-0">
                                R${' '}
                                {moneyMask(
                                  Number(digitation.valueInstallment).toFixed(2)
                                )}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="4">
                          <Card className="shadow">
                            <CardBody>
                              <h5 className="m-0">Valor Client</h5>
                              <p className="m-0">
                                R${' '}
                                {moneyMask(
                                  Number(digitation.valueLiberty).toFixed(2)
                                )}
                              </p>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  )}

                <Card className="shadow mb-3">
                  <CardHeader>
                    <h3 className="m-0">
                      <i className="fas fa-user"></i> Informações do Cliente
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="3">
                        <FormGroup>
                          <Label>CPF</Label>
                          <Input
                            name="cpf"
                            id="cpf"
                            value={digitation.cpf}
                            placeholder="Informar CPF do cliente"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                cpf: maskCPF(ev.target.value),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label>Nome</Label>
                          <Input
                            name="name"
                            id="name"
                            value={digitation.name}
                            placeholder="Digitar o nome do cliente"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                name: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Dt. nascimento</Label>
                          <Input
                            name="birthDate"
                            id="birthDate"
                            value={digitation.birthDate}
                            placeholder="Informar data de nascimento"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                birthDate: maskDate(ev.target.value),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>WhatsApp</Label>
                          <Input
                            name="whatsappNumber"
                            id="whatsappNumber"
                            value={digitation.phone}
                            placeholder="Informar WhatsApp"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                phone: phoneMask(ev.target.value),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>E-mail</Label>
                          <Input
                            name="email"
                            id="email"
                            value={digitation.email}
                            placeholder="Informar e-mail do cliente"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                email: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Matricula</Label>
                          <Input
                            name="registry"
                            id="registry"
                            value={digitation.registry}
                            placeholder="RG do cliente"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                registry: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>RG</Label>
                          <Input
                            name="documentRG"
                            id="documentRG"
                            value={digitation.documentRG}
                            placeholder="RG do cliente"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                documentRG: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Dt Emissão</Label>
                          <Input
                            name="dateEmissionRG"
                            id="dateEmissionRG"
                            value={digitation.dateEmissionRG}
                            placeholder="--/--/----"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                dateEmissionRG: maskDate(ev.target.value),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Orgão Emissor</Label>
                          <Input
                            name="organEmissionRg"
                            id="organEmissionRg"
                            value={digitation.organEmissionRg}
                            placeholder=""
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                organEmissionRg: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Estado Emissor</Label>
                          <select
                            className="form-control form-control-md"
                            name="stateEmissionRg"
                            id="stateEmissionRg"
                            value={digitation.stateEmissionRg}
                            placeholder="Informar WhatsApp"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                stateEmissionRg: ev.target.value,
                              });
                            }}
                          >
                            <option name="" disabled selected>
                              Selecione
                            </option>
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Sexo</Label>
                          <select
                            className="form-control form-control-md"
                            name="sex"
                            id="sex"
                            value={digitation.sex}
                            placeholder=""
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                sex: ev.target.value,
                              });
                            }}
                          >
                            <option name="" disabled selected>
                              Selecione
                            </option>
                            <option name="M">Masculino</option>
                            <option name="F">Feminino</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>Estado Civil</Label>
                          <select
                            className="form-control form-control-md"
                            name="stateCivil"
                            id="stateCivil"
                            value={digitation.stateCivil}
                            placeholder=""
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                stateCivil: ev.target.value,
                              });
                            }}
                          >
                            <option name="" disabled selected>
                              Selecione
                            </option>
                            <option name="SOLTEIRO">Solteiro</option>
                            <option name="CASADO">Casado</option>
                            <option name="DIVORCIADO">Divorciado</option>
                            <option name="VIUVO">Viúvo</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="9">
                        <FormGroup>
                          <Label>Nome da mãe</Label>
                          <Input
                            name="nameMother"
                            id="nameMother"
                            value={digitation.nameMother}
                            placeholder="Informar nome da mãe"
                            onChange={(ev) => {
                              setDigitation({
                                ...digitation,
                                nameMother: ev.target.value,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Address
                  setDigitation={setDigitation}
                  digitation={digitation}
                />
                <BankDigitation
                  setDigitation={setDigitation}
                  digitation={digitation}
                  showPix={false}
                  readonly={{
                    bank: false,
                    agency: false,
                  }}
                />

                {digitation?.documents && digitation?.documents.length > 0 && (
                  <Card className="shadow mt-2">
                    <CardHeader>
                      <h3 className="m-0">
                        <i className="fas fa-folder-open" /> Documentos enviados
                      </h3>
                    </CardHeader>
                    <CardBody>
                      {digitation?.documents.map((doc, key) => (
                        <a
                          className="btn btn-secondary"
                          href={doc.urlDocument}
                          target="_blank"
                          color="secondary"
                          key={`${doc.type}-${key}`}
                        >
                          <i className="fas fa-file" /> {key} -{' '}
                          {doc.typeTranslate}
                        </a>
                      ))}
                    </CardBody>
                  </Card>
                )}

                <Card className="shadow mt-2">
                  <CardHeader>
                    <h3 className="m-0">
                      <i className="fas fa-upload" /> Envio de documentos
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Alert color="secondary">
                      <h3 className="m-0">
                        Informações importantes sobre os arquivos
                      </h3>
                      <p className="m-0">Extensões permitidas: PDF, JPG</p>
                      <p className="m-0">Tamanho limite: 2Mb por arquivo</p>
                    </Alert>

                    <Row>
                      <Col md="4">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <Label>RG FRENTE</Label>
                            <Input
                              type="file"
                              name="RG_FRONT"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    RG_FRONT: {
                                      file: result.base64,
                                      name: file.name,
                                    },
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <Label>RG VERSO</Label>
                            <Input
                              type="file"
                              name="RG_BACK"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    RG_BACK: {
                                      file: result.base64,
                                      name: file.name,
                                    },
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <Label>CPF FRENTE</Label>
                            <Input
                              type="file"
                              name="CPF_FRONT"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    CPF_FRONT: {
                                      file: result.base64,
                                      name: file.name,
                                    },
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <Label>CPF VERSO</Label>
                            <Input
                              type="file"
                              name="CPF_BACK"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    CPF_BACK: {
                                      file: result.base64,
                                      name: file.name,
                                    },
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <Label>Extrato bancário</Label>
                            <Input
                              type="file"
                              name="DOCUMENT_BANK"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    DOCUMENT_BANK: {
                                      file: result.base64,
                                      name: file.name,
                                    },
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <Label>Comprovante Endereço</Label>
                            <Input
                              type="file"
                              name="DOCUMENT_ADDRESS"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    DOCUMENT_ADDRESS: {
                                      file: result.base64,
                                      name: file.name,
                                    },
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col>

                      {/* <Col md="12">
                        <Card className="shadow mb-2">
                          <CardBody>
                            <InputIncrementComponent
                              label="Outros"
                              name="OTHERS"
                              type="file"
                              onChange={(ev) => {
                                const file = ev.target.files[0] || null;
                                getBase64(file).then((result) => {
                                  setDigitation({
                                    ...digitation,
                                    OTHERS: result.base64,
                                  });
                                });
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Col> */}
                    </Row>
                  </CardBody>
                </Card>

                <Alert color="secondary" className="mt-5 border">
                  <h2 className="m-0">Anotações / Observação</h2>
                </Alert>
                <textarea
                  className="form-control"
                  placeholder="Anotações"
                  value={digitation.observation}
                  onChange={(ev) => {
                    setDigitation({
                      ...digitation,
                      observation: ev.target.value,
                    });
                  }}
                />

                {digitation.indicationId > 0 ? (
                  <>
                    <Alert color="secondary" className="mt-5 border">
                      <h2 className="m-0">INDICAÇÃO</h2>
                    </Alert>
                    <Alert color="warning">
                      Esta digitação possúi uma indicação
                    </Alert>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>Nome</Label>
                          <Input
                            value={digitation.indication.name}
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>CÓDIGO</Label>
                          <Input
                            value={digitation.indication.uuid}
                            placeholder=""
                            readOnly
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </CardBody>
              {![5, 11].includes(Number(digitation.statusAttendantId)) && (
                <CardFooter>
                  <Button color="success" onClick={handleSubmit}>
                    Salvar Digitação
                  </Button>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
        {digitationHistory.length > 0 ? (
          <Card className="mt-5">
            <CardHeader>
              <h4 className="m-0">HISTÓRICO - CONTRATOS DO CLIENTE</h4>
            </CardHeader>
            <CardBody>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>BANCO</th>
                    <th>Nº PROPOSTA</th>
                    <th>VALORES</th>
                    <th>STATUS</th>
                    <th>DATA CADASTRO</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {digitationHistory.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <p>{data.bank}</p>
                      </td>
                      <td>
                        <p>{data.numberProposal}</p>
                      </td>
                      <td>
                        <p>
                          <strong>VALOR CONTRATO:</strong>
                          {` `}
                          R$ {moneyMask(data.valueFinally)}
                        </p>
                        <p>
                          <strong>VALOR VALOR LIBERADO:</strong>
                          {` `}
                          R$ {moneyMask(data.valueLiberty)}
                        </p>
                      </td>
                      <td>
                        <p>
                          {data.situation} - {data.activity}
                        </p>
                      </td>
                      <td>
                        <p>{data.createdAt}</p>
                      </td>
                      <td>
                        <UncontrolledDropdown>
                          <DropdownToggle caret color="secondary">
                            Opções
                          </DropdownToggle>
                          <DropdownMenu dark>
                            <DropdownItem
                              href={`/admin/proposal-details/${
                                data.proposalId ?? 0
                              }`}
                              target="_blank"
                            >
                              <i className="fas fa-edit" /> Detalhes do Contrato
                            </DropdownItem>
                            {(data.febrabanId == '626' ||
                              data.febrabanId == '149' ||
                              data.febrabanId == '623' ||
                              data.febrabanId == '074') &&
                            data.proposalId > 0 ? (
                              <>
                                <DropdownItem
                                  onClick={() => {
                                    getLinkFormalize(
                                      data.numberProposal,
                                      data.febrabanId
                                    );
                                  }}
                                >
                                  <i className="fas fa-edit" /> Link de
                                  Formalização
                                </DropdownItem>
                              </>
                            ) : (
                              <></>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        ) : (
          <></>
        )}

        {clientNote.length > 0 ? (
          <Card className="mt-5">
            <CardHeader>
              <h4 className="m-0">ANOTAÇÕES / OBSERVAÇÕES</h4>
            </CardHeader>
            <CardBody style={{ maxHeight: '600px', overflow: 'auto' }}>
              {clientNote.map((data, index) => (
                <>
                  <div key={index} className="border p-4 rounded mb-2 mt-2">
                    <h3>
                      {data.name} - {data.createdAt}
                    </h3>
                    <p>{data.message}</p>
                  </div>
                </>
              ))}
            </CardBody>
          </Card>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
};

export default DigitationINSS;
