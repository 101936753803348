import InputFebrabanView from 'components/Form/Input/InputFebrabanView';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { indexProposalReportCsv } from 'services/Digitation/ProposalService';
import { listIndication } from 'services/Promoter/IndicationService';
import Swal from 'sweetalert2';

const FilterProposalFgts = ({
  buttonAditional = null,
  toggleShowList,
  setToggleShowList = () => { },
  filter,
  setFilter = () => { },
  handleSubmit,
}) => {
  const [indicationList, setIndicationList] = useState([]);

  const indication = async () => {
    await listIndication({ limit: 10000, status: 1, simpleHeader: 1 }).then(
      (result) => {
        setIndicationList(result?.data?.resultSet?.data ?? []);
      }
    );
  };

  const handleCsv = async () => {
    Swal.fire({
      title: 'Carregando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    await indexProposalReportCsv({ ...filter, isCsv: true }).then((res) => {
      Swal.fire({
        icon: 'info',
        title: 'Sucesso',
        text: res.data.message,
      }).then((result) => {
        if (!result.isConfirmed) return;
        history.push('/admin/report-schedule');
      });
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const filterParams = {
      dateStart: queryParams.get('dateStart') ?? null,
      dateEnd: queryParams.get('dateEnd') ?? null,
      status: queryParams.get('statusId') ?? null,
    };
    setFilter(filterParams);

    indication();
    handleSubmit(0, filterParams);

    const savedView = localStorage.getItem('IZIDEV_LIST_VIEW_CARD');
    setToggleShowList(savedView ? parseInt(savedView, 10) : 0);
  }, []);

  return (
    <>
      <Card className="shadow">
        <CardHeader className="border-0">
          <h3 className="m-0">
            <i className="fas fa-list-alt" /> PROPOSTAS DIGITADAS - FGTS
          </h3>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label>Período</Label>
                <InputGroup>
                  <Input
                    id="dateStart"
                    name="dateStart"
                    type="date"
                    className="border"
                    value={filter.dateStart}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateStart: ev.target.value,
                      });
                    }}
                  />
                  <Input
                    className="border"
                    id="dateEnd"
                    name="dateEnd"
                    type="date"
                    style={{ paddingLeft: '12px' }}
                    value={filter.dateEnd}
                    onChange={(ev) => {
                      setFilter({
                        ...filter,
                        dateEnd: ev.target.value,
                      });
                    }}
                  />

                  <select
                    className="form-control border pl-2"
                    id="typeDate"
                    name="typeDate"
                    onChange={(ev) => {
                      setFilter({ ...filter, typeDate: ev.target.value });
                    }}
                    onBlur={(ev) => {
                      setFilter({ ...filter, typeDate: ev.target.value });
                    }}
                  >
                    <option value="createdAt">DATA DE CADASTRO</option>
                    <option value="updatedAt">DATA DE ATUALIZAÇÃO</option>
                  </select>
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md="3">
              <InputFebrabanView
                defaultValue={
                  {
                    label: filter?.nameBank ?? 'Selecione...',
                    value: filter?.febraban
                  }
                }
                setFilter={(e) => {
                  setFilter({ ...filter, bank: e.febraban, nameBank: e.nameBank });
                }}
                isClearInput={true}
              />
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>CPF do Cliente</Label>
                <Input
                  id="cpf"
                  name="cpf"
                  type="text"
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      cpf: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Nº Proposta</Label>
                <Input
                  id="numberProposal"
                  name="numberProposal"
                  type="text"
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      numberProposal: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Nº Contrato</Label>
                <Input
                  id="numberProposalBank"
                  name="numberProposalBank"
                  type="text"
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      numberProposalBank: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Status</Label>
                <select
                  id="statusProposal"
                  name="statusProposal"
                  className="form-control"
                  value={filter.status}
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      status: ev.target.value,
                    });
                  }}
                >
                  <option value="">Selecione...</option>
                  <option value="2">Pago</option>
                  <option value="3">Em Análise</option>
                  <option value="1">Em Andamento</option>
                  <option value="4">Com Pendência</option>
                  <option value="5">Reprovado</option>
                  <option value="6">Cancelado</option>
                </select>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label>Indicado</Label>
                <select
                  id="indicationId"
                  name="indicationId"
                  className="form-control"
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      indicationId: ev.target.value,
                    });
                  }}
                >
                  <option value="">Selecione...</option>
                  {indicationList.length
                    ? indicationList.map((register, key) => (
                      <option key={key} value={register.id}>
                        {register.name}
                      </option>
                    ))
                    : ''}
                </select>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Estado</Label>
                <select
                  id="state"
                  name="state"
                  className="form-control"
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      state: ev.target.value,
                    });
                  }}
                >
                  <option value="" disabled="">
                    Selecione...
                  </option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Label>Cidade</Label>
                <Input
                  id="city"
                  name="city"
                  type="text"
                  onChange={(ev) => {
                    setFilter({
                      ...filter,
                      city: ev.target.value,
                    });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row className="w-100 d-flex align-items-center">
            <Col md="6">
              <Button
                color="success"
                onClick={() => {
                  handleSubmit(0);
                }}
              >
                Buscar
              </Button>
              <Button
                color="success"
                onClick={() => {
                  handleCsv();
                }}
              >
                EXCEL
              </Button>

              {buttonAditional}
            </Col>

            <Col className="d-flex justify-content-end">
              <div className="d-flex align-items-center justify-content-center switch-div">
                <Label className="switch m-0">
                  <Input
                    id="status"
                    type="checkbox"
                    checked={toggleShowList <= 0}
                    size="sm"
                    onChange={(ev) => {
                      const value = ev.target.checked ? 0 : 1;
                      localStorage.setItem('IZIDEV_LIST_VIEW_CARD', value);
                      setToggleShowList(value);
                    }}
                  />
                  <span className="slider round" />
                </Label>
                <span className="ml-2">Visualizar em card(s)</span>
              </div>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default FilterProposalFgts;
