import { useEffect, useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import { listIndication } from 'services/Promoter/IndicationService';
import Swal from 'sweetalert2';

const InputIndicationView = ({
  filter,
  setFilter,
  defaultValue = { value: null, label: null },
  isClearInput = false,
  isMultSelect = false,
}) => {
  const [indicationList, setIndicationList] = useState([]);
  const handleIndexIndication = async () => {
    await listIndication({
      limit: 15000,
      status: 1,
      simpleHeader: 1,
    })
      .then((result) => {
        if (!result?.data?.resultSet?.data) {
          return;
        }
        setIndicationList(
          result?.data?.resultSet?.data.map((res) => {
            return {
              value: res.id,
              label: `${res.name} - ${res.uuid}`,
            };
          })
        );
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleIndexIndication();
  }, []);

  return (
    <FormGroup>
      <Label>Link de Indicação / Vendedor</Label>
      {indicationList.length > 0 && (
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue}
          options={indicationList}
          isLoading={indicationList.length <= 0}
          placeholder={`Selecione...`}
          isClearable={isClearInput}
          isMulti={isMultSelect}
          value={
            filter?.indicationId
              ? indicationList.find(
                  (option) => option.value === filter?.indicationId
                )
              : defaultValue
          }
          onChange={(res) => {
            if (isMultSelect) {
              setFilter(res);
              return;
            }

            setFilter({
              ...filter,
              indicationId: res?.value ?? null,
            });
          }}
        />
      )}

      {indicationList.length <= 0 && <p>Carregando...</p>}
    </FormGroup>
  );
};

export default InputIndicationView;
