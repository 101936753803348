import Panel from './views/Panel/index.js';
import Profile from './views/Profile/index.js';
import User from './views/User/index.js';
import AccessBank from './views/AccessBank/index.js';
import Index from './views/Dashboard/Index.js';
import ReportSchedule from './views/Report/Schedule/index.js';
import Indication from './views/Indication/index.js';
import { Integrations } from './views/Integrations';
import INSS from 'views/INSS/index.js';
import DigitationINSS from 'views/INSS/digitation/index.js';
import Leads from 'views/Leads/Index.js';
import ReportIndication from 'views/ReportIndication/Index.js';
import SearchMargem from 'views/SearchMargem/index.js';
import ImportMargem from 'views/SearchMargem/importMargem.js';
import DetailsMargem from 'views/SearchMargem/detailsMargem.js';
import LandingPageConsigned from 'views/LandingPage/LandingPageConsigned.js';
import ProposalReportFgts from 'views/FGTS/ProposalReportFgts.js';
import ReportSalePerIndication from 'views/ReportSalePerIndication/Index.js';
import AccessBankConfigure from 'views/AccessBank/accessBankConfigure.js';
import DashboardResume from 'views/Dashboard/resume/index.js';
import SimulationMultiBanksView from 'views/LandingPage/SimulationMultiBanksView.js';
import LandingPageFGTSView from 'views/LandingPage/LandingPageFGTSView.js';
import BannerGenerator from 'views/BannerGenerator/index.js';
import ProposalDetails from 'views/Proposal/ProposalDetails.js';
import SimulationDetails from 'views/Proposal/SimulationDetails.js';
import LeadScheduleView from 'views/Leads/Schedule/LeadScheduleView.js';
import ImportLeadView from 'views/FGTS/ImportLeadView.js';
import ComissionTableView from 'views/Comission/Report/comissionTableView.js';
import ComissionTableRegisterView from 'views/Comission/Register/index.js';
import ComissionProposalView from 'views/Comission/Report/comissionProposalView.js';
import LeadImportedView from 'views/FGTS/LeadImportedView.js';
import PersonalCreditView from 'views/PersonalCredit/index.js';
import DigitationPersonalCreditView from 'views/PersonalCredit/digitation.js';
import IziFormView from 'views/LandingPage/IziFormView';
import DigitationManualView from 'views/INSS/digitation/DigitationManualView.js';
import ReportDigitationView from 'views/INSS/digitation/ReportDigitationView.js';
import SimulationPersonalCreditView from 'views/LandingPage/SimulationPersonalCreditView.js';
import ScheduleView from 'views/Schedule/index.js';
import DashboardView from 'views/Dashboard/DashboardView.js';
import IziLinkView from 'views/LandingPage/IziLink/Index.js';
import DigitationFgtsManualView from 'views/FGTS/DigitationFgtsManualView.js';
import LandingPageMultiProduct2023View from 'views/LandingPage/LandingPageMultiProduct2023View.js';
import AutoContractionView from 'views/INSS/Page/AutoContractionView.js';
import CrefazAutoContractionView from 'views/PersonalCredit/Page/CrefazAutoContractionView.js';
import PaymentView from 'views/Profile/PaymentView.js';
import NewSimulatorFgtsView from 'views/FGTS/Proposal/NewSimulatorFgtsView.js';
import TableComissionTransferView from 'views/GrandinoBank/TableComission/TableComissionTransferView.js';
import ProposalGrandinoBankReportFgts from 'views/FGTS/ProposalGrandinoBankFgts.js';
import CreateOperationManualView from 'views/FGTS/CreateOperationManualView.js';
import SimulationGrandinoBankFgtsView from 'views/FGTS/SimulationGrandinoBankFgtsView.js';
import ContractSignReportView from 'views/Register/ContractSign/ContractSignReportView.js';
import SmsReportView from 'views/Register/Sms/SmsReportView.js';
import ContractSignResumeView from 'views/Register/ContractSign/ContractSignResumeView.js';
import ResetPasswordView from 'views/User/ResetPasswordView.js';
import ScheduleSimulationView from 'views/FGTS/ScheduleSimulationView.js';
import ContractSignTemplateView from 'views/Register/ContractSign/template/ContractSignTemplateView.js';
import ConfigUpdateUserView from 'views/Register/ContractSign/ConfigUpdateUserView.js';
import ContractSignUpdateUserView from 'views/Register/ContractSign/ContractSignUpdateUserView.js';
import ReportUpdateUserContractGenerateView from 'views/Register/ContractSign/ReportUpdateUserContractGenerateView.js';
import SmsRechargeView from 'views/Register/Sms/SmsRechargeView.js';
import SmsCampaignView from 'views/Register/Sms/SmsCampaignView.js';
import RegisterUserView from 'views/User/RegisterUserView.js';
import LandingPagePolicyPrivacyView from 'views/LandingPage/LandingPagePolicyPrivacyView.js';
import DigitationCrefazView from 'views/PersonalCredit/digitationCrefazView.js';
import SimulationAbandonedReportView from 'views/FGTS/Report/SimulationAbandonedReportView.js';
import LandingPageFGTS2024View from 'views/LandingPage/LandingPageFGTS2024View.js';
import LandingPageMultiProduct2024View from 'views/LandingPage/LandingPageMultiProduct2024View.js';
import IndicationConversionView from 'views/Indication/IndicationConversionView.js';
import PersonalCreditCreateOperationManualView from 'views/PersonalCredit/PersonalCreditCreateOperationManualView.js';
import DigitationManualiCredView from 'views/INSS/digitation/DigitationManualiCredView.js';
import ConsignedCreateOperationManualView from 'views/INSS/Page/ConsignedCreateOperationManualView.js';
import ImportProposalView from 'views/Import/ImportProposalView.js';
import AccessBankOrderView from 'views/AccessBank/AccessBankOrderView.js';
import TableComissionView from 'views/Comission/Report/tableComissionView.js';
import SimulationErrorReportView from 'views/FGTS/Report/SimulationErrorReportView.js';
import ClientBirthDateReportView from 'views/Register/Client/Report/ClientBirthDateReportView.js';
import IndicationFormView from 'views/Indication/IndicationFormView.js';
import NotificationView from 'views/Notification/NotificationView.js';
import ImportCommissionView from 'views/Import/ImportCommissionView.js';
import ComissionPromoterReportView from 'views/Comission/Report/comissionPromoterReportView.js';
import ComissionImportedView from 'views/Import/ComissionImportedView.js';
import NewProposalView from 'views/Consigned/newProposalView.js';
import CreateStoreView from 'views/Store/CreateStoreView.js';
import ListStoreView from 'views/Store/ListStoreView.js';
import ClientLifeView from 'views/Client/ClientLifeView.js';
import MarginSearchView from 'views/MarginSearch/MarginSearchView.js';
import NewProposalCrefisaView from 'views/PersonalCredit/newProposalCrefisaView.js';
import PersonalCreditDigitationQueueView from 'views/PersonalCredit/PersonalCreditDigitationQueueView.js';
import ImportOptinFgtsView from 'views/Import/ImportOptinFgtsView.js';
import ImportConsignedView from 'views/Consigned/ImportConsignedView.js';
import DashboardIndicationView from 'views/Indication/DashboardIndicationView.js';
import TableComissionBankView from 'views/Config/TableComissionBankView.js';
import ImportNotificationFGTSView from 'views/FGTS/ImportNotificationFGTSView.js';
import NotificationFgtsImportedView from 'views/FGTS/NotificationFgtsImportedView.js';
import ClosingCommissionView from 'views/Comission/ClosingCommissionView.js';
import ImportLimitAvailableBmgView from 'views/Import/ImportLimitAvailableBmgView.js';

let routes = [
  {
    path: '/dashboard/home',
    name: 'Principal',
    icon: 'ni ni-tv-2 text-primary',
    component: DashboardView,
    layout: '/admin',
  },
  {
    path: '/index',
    name: 'Principal',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
  },
  {
    path: '/dashboard/resume',
    name: 'Resumo Vendas',
    icon: 'fas fa-tachometer-alt text-primary',
    component: DashboardResume,
    layout: '/admin',
  },
  {
    path: '/schedule',
    name: 'Agenda',
    icon: 'fas fa-calendar-alt text-primary',
    component: ScheduleView,
    layout: '/admin',
  },
  {
    path: '/payment',
    name: 'Pagamento',
    icon: 'fas fa-calendar-alt text-primary',
    component: PaymentView,
    layout: '/admin',
  },
  {
    name: 'Lojas',
    icon: 'fas fa-store',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/store/list',
        name: 'Minhas lojas',
        component: ListStoreView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/store/create',
        name: 'Cadastrar',
        component: CreateStoreView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    path: '/client/life',
    name: 'Vida do Cliente',
    icon: 'fa fa-solid fa-user-plus',
    component: ClientLifeView,
    layout: '/admin',
  },
  {
    name: 'Comissionamento',
    icon: 'fas fa-percentage text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/comission/table/list',
        name: 'Listar tabelas',
        component: ComissionTableView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/comission/table/register',
        name: 'Cadastrar Tabela',
        component: ComissionTableRegisterView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/comission/table-comission',
        name: 'Tabela de comissionamento',
        component: TableComissionView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/comission/proposal',
        name: 'Propostas',
        component: ComissionProposalView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/mycomission',
        name: 'Meu comissionamento',
        component: ComissionPromoterReportView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/comission/payment',
        name: 'Fechamento de Comissão',
        component: ClosingCommissionView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    name: 'FGTS',
    icon: 'fas fa-list text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/fgts/operation/manual',
        name: 'Digitar Operação Manual',
        component: CreateOperationManualView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/proposal',
        name: 'Propostas Digitadas',
        component: ProposalReportFgts,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/grandino/simulation',
        name: 'Simulações Realizadas',
        component: SimulationGrandinoBankFgtsView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/grandino/proposal',
        name: 'Propostas Digitadas',
        component: ProposalGrandinoBankReportFgts,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/report/simulation-abandoned',
        name: 'Simulações não concluídas',
        component: SimulationAbandonedReportView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/report/simulation-error',
        name: 'Clientes com erro',
        component: SimulationErrorReportView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/simulator/multi-banks',
        name: 'Personalizar Simulador MultiBancos',
        component: SimulationMultiBanksView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/digitation-proposal',
        name: 'Realizar Digitação',
        component: DigitationFgtsManualView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/schedule',
        name: 'Retrabalhar Lead',
        component: LeadScheduleView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/schedule-simulation-manual',
        name: 'Agendar Simulação',
        component: ScheduleSimulationView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/import/lead',
        name: 'Importar Lead',
        component: ImportLeadView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/import/optin',
        name: 'Importação base Offline',
        component: ImportOptinFgtsView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/fgts/lead/imported/:importedId',
        name: 'Importar Lead',
        component: LeadImportedView,
        layout: '/admin',
        new: false,
      },
      {
        path: '/fgts/operation',
        name: 'Digitar Proposta',
        component: NewSimulatorFgtsView,
        layout: '/admin',
        new: false,
      },
      {
        path: '/fgts/tableComissionRule',
        name: 'Config Regra Repasse',
        component: TableComissionTransferView,
        layout: '/admin',
        new: false,
      },
      {
        path: '/fgts/import/notification',
        name: 'Importação - Notificações',
        component: ImportNotificationFGTSView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    name: 'Integração Bancos',
    icon: 'fas fa-shopping-cart text-primary',
    layout: '/admin',
    childrens: [
      {
        path: '/access-bank',
        name: 'Configurar',
        component: AccessBank,
        layout: '/admin',
      },
      {
        path: '/access-bank-order',
        name: 'Ordenar',
        component: AccessBankOrderView,
        layout: '/admin',
      },
    ],
  },
  // {
  //   name: 'Auxilio Brasil',
  //   icon: 'fas fa-list text-primary',
  //   layout: '/admin',
  //   new: true,
  //   childrens: [
  //     {
  //       path: '/auxilio-brasil/simulation',
  //       name: 'Simulações',
  //       component: SimulationReportAuxilioBrasil,
  //       layout: '/admin',
  //       new: true
  //     },
  //     {
  //       path: '/auxilio-brasil/form/:simulationId/:proposalId',
  //       name: 'Simulações',
  //       component: AuxilioBrasilForm,
  //       layout: '/admin',
  //       new: true
  //     }
  //   ]
  // },
  // {
  //   name: 'Cartão Benefício',
  //   icon: 'fas fa-list text-primary',
  //   layout: '/admin',
  //   new: true,
  //   childrens: [
  //     {
  //       path: '/card-benefit/simulation',
  //       name: 'Simulações',
  //       component: CardBenefitView,
  //       layout: '/admin',
  //       new: true
  //     }
  //   ]
  // },
  {
    name: 'Crédito pessoal',
    icon: 'fas fa-list text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/personal-credit/operation/manual',
        name: 'Digitar Operação Manual',
        component: PersonalCreditCreateOperationManualView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/personal-credit/simulation',
        name: 'Esteira de vendas',
        component: PersonalCreditView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/personal-credit/digitation/queue',
        name: 'Esteira de digitação',
        component: PersonalCreditDigitationQueueView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/personal-credit/proposal/crefisa',
        name: 'Digitar Crefisa',
        component: NewProposalCrefisaView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/personal-credit/crefaz',
        name: 'Digitar Crefaz',
        component: DigitationCrefazView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/personal-credit/re-simulation-crefaz/:simulationId',
        name: 'Re Digitar Crefaz',
        component: DigitationCrefazView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/personal-credit/digitation',
        name: 'Digitar Novo Saque',
        component: DigitationPersonalCreditView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/simulator/personal-credit',
        name: 'Config Novo Saque',
        component: SimulationPersonalCreditView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/simulator/crefaz',
        name: 'Config Crefaz',
        component: CrefazAutoContractionView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    name: 'IziSign',
    icon: 'fas fa-list text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/izisign/list',
        name: 'Listagem',
        component: ContractSignReportView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/izisign/report-updateUser-contractgenerate',
        name: 'Atualização Cadastro - Usuário sistema',
        component: ReportUpdateUserContractGenerateView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/izisign/resume',
        name: 'Resumo',
        component: ContractSignResumeView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/izisign/template',
        name: 'Template',
        component: ContractSignTemplateView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/izisign/config-updateuser',
        name: 'Configurar Assinatura',
        component: ConfigUpdateUserView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/izisign/update-user',
        name: 'Atualizar cadastro',
        component: ContractSignUpdateUserView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    name: 'SMS',
    icon: 'fas fa-sms text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/sms/list',
        name: 'Relatório de envios',
        component: SmsReportView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/sms/recharge-credit',
        name: 'Recarregar créditos',
        component: SmsRechargeView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/sms/campaign',
        name: 'Campanha',
        component: SmsCampaignView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    name: 'Consignado',
    icon: 'fas fa-list text-primary',
    layout: '/admin',
    childrens: [
      {
        path: '/consigned/operation/manual',
        name: 'Digitar Operação Manual',
        component: ConsignedCreateOperationManualView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/inss/proposal/report',
        name: 'Relatório de Propostas',
        component: ReportDigitationView,
        layout: '/admin',
      },
      {
        path: '/inss/digitation',
        name: 'Realizar Digitação',
        component: DigitationManualView,
        layout: '/admin',
      },
      {
        path: '/consigned/digitation/icred',
        name: 'Realizar Digitação INSS - iCred',
        component: DigitationManualiCredView,
        layout: '/admin',
      },
      {
        path: '/consigned/lp/auto-contratacao',
        name: 'Configurar Auto Contratação',
        component: AutoContractionView,
        layout: '/admin',
      },
      {
        path: '/consigned/search-margin',
        name: 'Consultar Margem',
        component: MarginSearchView,
        layout: '/admin',
      },
      {
        path: '/consigned/digitation/simulation',
        name: 'Digitar Contrato',
        component: NewProposalView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/consigned/import',
        name: 'Importar aumento margem',
        component: ImportConsignedView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    name: 'Indicação',
    icon: 'fas fa-hands-helping text-primary',
    layout: '/admin',
    childrens: [
      {
        path: '/dashboard/indication',
        name: 'Dashboard',
        component: DashboardIndicationView,
        layout: '/admin',
      },
      {
        path: '/indication',
        name: 'Links de Indicação',
        component: Indication,
        layout: '/admin',
      },
      {
        path: '/report-indication/proposal',
        name: 'Vendas',
        component: ReportIndication,
        layout: '/admin',
      },
      {
        path: '/report-indication/sale/indication',
        name: 'Vendas Por Indicação',
        component: ReportSalePerIndication,
        layout: '/admin',
      },
      {
        path: '/report-indication/conversion',
        name: 'Conversão Simulação X Propostas',
        component: IndicationConversionView,
        layout: '/admin',
      },
      {
        path: '/indication-form',
        name: 'Formulário de captação',
        component: IndicationFormView,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'Relatórios',
    icon: 'fas fa-chart-bar text-primary',
    layout: '/admin',
    childrens: [
      {
        path: '/panel',
        name: 'Monitoramento',
        component: Panel,
        layout: '/admin',
      },
      {
        path: '/report-schedule',
        name: 'Relatórios Excel',
        component: ReportSchedule,
        layout: '/admin',
      },
    ],
  },
  {
    name: 'Importação',
    icon: 'fas fa-chart-bar text-primary',
    layout: '/admin',
    childrens: [
      {
        path: '/import/proposal',
        name: 'Importar Propostas',
        component: ImportProposalView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/import/comission',
        name: 'Importar Comissionamento',
        component: ImportCommissionView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/import/bmg/limit-available',
        name: 'Limite de Saque BMG',
        component: ImportLimitAvailableBmgView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/import/comission-imported/:fileImportedId',
        name: 'Comissionamento - Registros importados',
        component: ComissionImportedView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/import/notification/fgts/:fileImportedId',
        name: 'Notificações FGTS - Registros importados',
        component: NotificationFgtsImportedView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    path: '/access-bank-configure/:febrabanId',
    name: 'Integração Bancos - Configuração',
    icon: 'fas fa-key text-primary',
    component: AccessBankConfigure,
    layout: '/admin',
  },
  {
    path: '/search-margem',
    name: 'Consultar Margem',
    icon: 'fas fa-key text-primary',
    component: SearchMargem,
    layout: '/admin',
  },
  {
    path: '/details-margem/:id',
    name: 'Consultar Margem',
    icon: 'fas fa-key text-primary',
    component: DetailsMargem,
    layout: '/admin',
  },
  {
    path: '/import-margem',
    name: 'Importar Margem',
    icon: 'fas fa-key text-primary',
    component: ImportMargem,
    layout: '/admin',
  },
  {
    path: '/user/list',
    name: 'Usuários do sistema',
    icon: 'fas fa-users text-primary',
    component: User,
    layout: '/admin',
  },
  {
    path: '/user/register/new',
    name: 'Usuários do sistema - Cadastrar',
    icon: 'fas fa-users text-primary',
    component: RegisterUserView,
    layout: '/admin',
  },
  {
    path: '/user/register/:id',
    name: 'Usuários do sistema - Cadastrar',
    icon: 'fas fa-users text-primary',
    component: RegisterUserView,
    layout: '/admin',
  },
  {
    path: '/integrations',
    name: 'Integrações',
    icon: 'fas fa-code text-primary',
    component: Integrations,
    layout: '/admin',
  },
  {
    path: '/profile',
    name: 'Perfil',
    icon: 'fas fa-user-circle text-primary',
    component: Profile,
    layout: '/admin',
  },
  {
    path: '/proposals',
    name: 'Esteira de Propostas',
    component: INSS,
    layout: '/admin',
    new: true,
    icon: 'fas fa-receipt text-primary',
  },

  {
    name: 'Clientes',
    icon: 'fas fa-swimmer text-primary',
    layout: '/admin',
    childrens: [
      {
        path: '/leads',
        name: 'Meus Leads',
        component: Leads,
        layout: '/admin',
        new: true,
      },
      {
        path: '/client/report/birthDate',
        name: 'Clientes por aniversário',
        component: ClientBirthDateReportView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    path: '/digitation-inss',
    name: 'DIGITAR PROPOSTA',
    component: DigitationINSS,
    layout: '/admin',
    new: true,
    icon: 'fas fa-user-circle text-primary',
  },
  {
    path: '/digitation-inss-show/:id',
    name: 'DIGITAR PROPOSTA',
    component: DigitationINSS,
    layout: '/admin',
    new: true,
    icon: 'fas fa-user-circle text-primary',
  },
  {
    path: '/izilink',
    name: 'IziLink',
    icon: 'fas fa-link text-primary',
    component: IziLinkView,
    layout: '/admin',
    new: true,
  },
  {
    path: '/notification',
    name: 'Notificações',
    icon: 'fas fa-link text-primary',
    component: NotificationView,
    layout: '/admin',
    new: true,
  },
  {
    path: '/gerador/banner',
    name: 'iziBanners',
    icon: 'ni ni-tv-2 text-primary',
    component: BannerGenerator,
    layout: '/admin',
  },
  {
    name: 'IziPages',
    icon: 'fas fa-solid fa-palette text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/landing-page/multi-product/2023',
        name: 'Multi-Produtos 2023',
        component: LandingPageMultiProduct2023View,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/multi-product/2024',
        name: 'Multi-Produtos 2024',
        component: LandingPageMultiProduct2024View,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/fgts',
        name: 'LP FGTS',
        component: LandingPageFGTSView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/lp-fgts/2024',
        name: 'LP FGTS 2024',
        component: LandingPageFGTS2024View,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/consigned',
        name: 'LP Consignado',
        component: LandingPageConsigned,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/iziform',
        name: 'IziForm',
        component: IziFormView,
        layout: '/admin',
        new: true,
      },
      {
        path: '/landing-page/policy-privacy',
        name: 'Politica de privacidade',
        component: LandingPagePolicyPrivacyView,
        layout: '/admin',
        new: true,
      },
      // {
      //   path: '/landing-page/simulator/auxilio-brasil',
      //   name: 'Auxilio Brasil',
      //   component: SimulationAuxilioBrasilView,
      //   layout: '/admin',
      //   new: true
      // },
      // {
      //   path: '/landing-page/simulator/cardBenefit',
      //   name: 'Cartão Benefício',
      //   component: SimulationCardBenefitView,
      //   layout: '/admin',
      //   new: true
      // }
    ],
  },
  {
    name: 'Configuração',
    icon: 'fas fa-solid fa-palette text-primary',
    layout: '/admin',
    new: true,
    childrens: [
      {
        path: '/config/table-bank/promoter',
        name: 'Tabela Banco',
        component: TableComissionBankView,
        layout: '/admin',
        new: true,
      },
    ],
  },
  {
    path: '/proposal-details/:id',
    name: 'Detalhes da Proposta',
    component: ProposalDetails,
    layout: '/admin',
    new: true,
  },
  {
    path: '/simulation-details/:id',
    name: 'Detalhes da Simulação',
    component: SimulationDetails,
    layout: '/admin',
    new: true,
  },
  {
    path: '/update-password',
    name: 'Detalhes da Simulação',
    component: ResetPasswordView,
    layout: '/admin',
    new: true,
  },
];
export default routes;
