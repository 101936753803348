import api from 'services/api';

export const listSellersPaymentComissionService = async (
  data = {
    dateStart: null,
    dateEnd: null,
    isPaid: null,
  }
) => {
  return await api.get(`v3/comission/payment/seller`, {
    params: data,
  });
};

export const paydComissionSellerService = async (
  data = {
    dateStart: null,
    dateEnd: null,
    isPaid: null,
  }
) => {
  return await api.post(`v3/comission/payment/seller`, data);
};
