import CardSimulation from 'components/Cards/CardSimulation';
import FilterDefault from 'components/Form/FilterDefault';
import Header from 'components/Headers/Header';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableData,
  TableHeader,
} from 'components/Table';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Badge,
  Card,
  CardHeader,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown,
} from 'reactstrap';
import { showLinkFormalize } from 'services/Digitation/DigitationService';
import { IndexProposalDefaultReport } from 'services/Digitation/ProposalService';
import { scheduleExcel } from 'services/Report/Schedule';
import Swal from 'sweetalert2';
import { maskCPF } from 'utils/mask';
import { phoneMask } from 'utils/mask';
import PaginationSelector from 'views/Dashboard/component/PaginationSelector';
import { CaretRight } from 'views/Dashboard/styles';
import { Title } from 'views/Dashboard/styles';
import CardReportDigitationComponent from './component/cardReportDigitationComponent';
import { MobileTitle } from 'views/Dashboard/styles';
import ModalTagComponent from 'components/ModalTag/ModalTagComponent';

const ReportDigitationView = () => {
  const history = useHistory();
  const [toggleShowList, setToggleShowList] = useState(0);
  const [filter, setFilter] = useState({});
  const [resultSet, setResultSet] = useState([]);
  const [pages, setPages] = useState({ pages: 0 });
  const [tableDetails, setTableDetails] = useState(null);

  const [tagDataModal, setTagDataModal] = useState({
    isOpen: false,
    clientId: null,
  });

  const handleTableDetails = (index) => {
    if (tableDetails === index) return setTableDetails(null);

    setTableDetails(index);
  };

  const handleList = (page = 0) => {
    const newFilter = {
      ...filter,
      page: typeof page == 'number' ? page : 0,
    };

    Swal.fire({
      title: 'Carregando',
      text: 'Por favor aguarde...',
      // message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    IndexProposalDefaultReport(1, newFilter)
      .then(({ data }) => {
        Swal.close();
        const { resultSet } = data;
        setResultSet(resultSet.data);
        setPages(resultSet.pagination);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const getLinkFormalize = async (numberProposal, febrabanId) => {
    Swal.fire({
      title: 'Buscando, Por favor aguarde...',
      message: 'Por favor aguarde',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showLinkFormalize(numberProposal, febrabanId)
      .then((res) => {
        const link = res.data.resultSet;
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          html: `<input class="form-control form-control" type="text" value="${link}" />`,
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleRedirectProposal = () => {
    history.push('/admin/consigned/digitation/simulation');
  };

  return (
    <>
      <Header showCards={false} titlePage="INSS - Propostas" />
      <Container className="mt--9" fluid>
        <CardSimulation serviceId="1" />
        <FilterDefault
          title="INSS - Propostas"
          data={filter}
          setData={setFilter}
          setToggleShowList={setToggleShowList}
          toggleShowList={toggleShowList}
          handleSubmit={handleList}
          handleRedirectProposal={handleRedirectProposal}
          handleSubmitCsv={() => {
            scheduleExcel(22, filter, history);
          }}
          config={{
            showBtnExcel: true,
            showFilterStatusText: false,
            showBtnProposal: true,
            useFilterOperator: true,
            showFilterHasBalance: true,
            showFilterTag: true,
            showBtnAditional: true,
            btnAditional: (
              <div className="float-right">
                <div className="d-flex align-items-center switch-div">
                  <Label className="switch">
                    <Input
                      id="status"
                      type="checkbox"
                      checked={toggleShowList <= 0}
                      size="sm"
                      onChange={(ev) => {
                        const value = ev.target.checked ? 0 : 1;
                        localStorage.setItem('IZIDEV_LIST_VIEW_CARD', value);
                        setToggleShowList(value);
                      }}
                    />
                    <span className="slider round" />
                  </Label>
                  <span className="ml-2">Visualizar em card(s)</span>
                </div>
              </div>
            ),
          }}
        />
        <Card className="mt-4 mb-5 mb-xl-0">
          <CardHeader className="border mb-4">
            <Title>Simulações</Title>
          </CardHeader>

          {resultSet.length > 0 ? (
            <>
              <PaginationSelector pages={pages.pages} list={handleList} />
              {toggleShowList === 1 && (
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Data Cadastro</TableHead>
                      <TableHead>Cliente</TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Valores
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell">
                        Status
                      </TableHead>
                      <TableHead className="d-none d-xl-table-cell" />
                      <TableHead className="d-xl-none" />
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {resultSet.map((register, index) => (
                      <>
                        <TableRow style={{ borderBottom: 0 }}>
                          {register?.tags?.length > 0 && (
                            <TableData colSpan={5}>
                              <div className="bg-secondary rounded p-2">
                                {register.tags.map((tag, tagKey) => (
                                  <Badge
                                    key={tagKey}
                                    className={`${tag.color} m-1 font-12 shadow`}
                                  >
                                    {tag.title}
                                  </Badge>
                                ))}
                              </div>
                            </TableData>
                          )}
                        </TableRow>
                        <TableRow style={{ borderTop: 0 }} key={index}>
                          <TableData>
                            <div>{register.createdAt}</div>
                          </TableData>
                          <TableData>
                            <h3>{register.name}</h3>
                            <div>
                              CPF: <strong>{maskCPF(register.cpf)}</strong>
                            </div>
                            <div>
                              Simulações:{' '}
                              <Badge color="success">{register.qtd}</Badge>
                            </div>
                            {register.indicationCode && (
                              <>
                                <div>
                                  Código Indicado:{' '}
                                  <strong className="text-warning">
                                    {register.indicationCode}
                                  </strong>
                                </div>

                                <div>
                                  Nome Indicado:{' '}
                                  <strong className="text-warning">
                                    {register.indicationName}
                                  </strong>
                                </div>
                              </>
                            )}

                            <a
                              href={`https://wa.me/55${register.whatsappNumber}`}
                              target="_blank"
                              className="btn btn-link p-0"
                            >
                              <i className="fab fa-whatsapp" />{' '}
                              {phoneMask(register.whatsappNumber)}
                            </a>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <div>
                              Vlr. Bruto:{' '}
                              <strong>
                                R$ {register.valueFinally ?? '0.00'}
                              </strong>
                            </div>
                            <div>
                              Vlr. Liquido:{' '}
                              <strong>
                                R$ {register.valueLiberty ?? '0.00'}
                              </strong>
                            </div>
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <div>
                              Banco:{' '}
                              <strong>
                                {register.febrabanId} - {register.bank}
                              </strong>
                            </div>
                            {register.numberProposal ? (
                              <>
                                <div>
                                  Nº Contrato:{' '}
                                  <strong>{register.numberProposal}</strong>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div>
                              Status Digitação:{' '}
                              <strong>{register.status}</strong>
                            </div>
                            {register.messageReturnService && (
                              <>
                                <hr className="mt-2 mb-2" />
                                <h5 className="m-0 text-danger">
                                  <strong>
                                    {register.messageReturnService}
                                  </strong>
                                </h5>
                              </>
                            )}
                          </TableData>
                          <TableData className="d-none d-xl-table-cell">
                            <UncontrolledDropdown>
                              <DropdownToggle caret color="secondary">
                                Opções
                              </DropdownToggle>
                              <DropdownMenu dark>
                                <DropdownItem
                                  href={`/admin/proposal-details/${register.proposalId ?? 0
                                    }`}
                                  target="_blank"
                                >
                                  <i className="fas fa-edit" /> Detalhes do
                                  Contrato
                                </DropdownItem>

                                <DropdownItem
                                  onClick={() => {
                                    setTagDataModal({
                                      isOpen: true,
                                      clientId: register.clientId,
                                    });
                                  }}
                                >
                                  <i className="fas fa-tags" /> Etiquetas
                                </DropdownItem>
                                {(register.febrabanId == '623' ||
                                  register.febrabanId == '149') &&
                                  register.status === 'DIGITAÇÃO REALIZADA' && (
                                    <DropdownItem
                                      onClick={() => {
                                        getLinkFormalize(
                                          register.numberProposal,
                                          register.febrabanId
                                        );
                                      }}
                                    >
                                      <i className="fas fa-paper-plane" /> Link
                                      de Formalização
                                    </DropdownItem>
                                  )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </TableData>

                          <TableData className="d-xl-none">
                            <CaretRight
                              active={tableDetails === index}
                              onClick={() => handleTableDetails(index)}
                            />
                          </TableData>
                        </TableRow>
                        {tableDetails === index && (
                          <TableRow className="d-flex flex-column">
                            <TableData>
                              <MobileTitle>Valores</MobileTitle>
                              <div>
                                Vlr. Bruto:{' '}
                                <strong>
                                  R$ {register.valueFinally ?? '0.00'}
                                </strong>
                              </div>
                              <div>
                                Vlr. Liquido:{' '}
                                <strong>
                                  R$ {register.valueLiberty ?? '0.00'}
                                </strong>
                              </div>
                            </TableData>
                            <TableData className="p-3">
                              <MobileTitle>Banco</MobileTitle>
                              <div>
                                Banco:{' '}
                                <strong>
                                  {register.febrabanId} - {register.bank}
                                </strong>
                              </div>
                              {register.numberProposal ? (
                                <>
                                  <div>
                                    Nº Contrato:{' '}
                                    <strong>{register.numberProposal}</strong>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div>
                                Status Digitação:{' '}
                                <strong>{register.status}</strong>
                              </div>
                              {register.messageReturnService && (
                                <>
                                  <hr />
                                  <h5 className="m-0 text-danger">
                                    Nº Contrato:{' '}
                                    <strong>
                                      {register.messageReturnService}
                                    </strong>
                                  </h5>
                                </>
                              )}
                            </TableData>
                            <TableData className="p-3">
                              <MobileTitle>Opções</MobileTitle>
                              <UncontrolledDropdown>
                                <DropdownToggle caret color="secondary">
                                  Opções
                                </DropdownToggle>
                                <DropdownMenu dark>
                                  <DropdownItem
                                    href={`/admin/proposal-details/${register.proposalId ?? 0
                                      }`}
                                    target="_blank"
                                  >
                                    <i className="fas fa-edit" /> Detalhes do
                                    Contrato
                                  </DropdownItem>

                                  <DropdownItem
                                    onClick={() => {
                                      setTagDataModal({
                                        isOpen: true,
                                        clientId: register.clientId,
                                      });
                                    }}
                                  >
                                    <i className="fas fa-tags" /> Etiquetas
                                  </DropdownItem>
                                  {(register.febrabanId == '623' ||
                                    register.febrabanId == '149') &&
                                    register.status ===
                                    'DIGITAÇÃO REALIZADA' && (
                                      <DropdownItem
                                        onClick={() => {
                                          getLinkFormalize(
                                            register.numberProposal,
                                            register.febrabanId
                                          );
                                        }}
                                      >
                                        <i className="fas fa-paper-plane" />{' '}
                                        Link de Formalização
                                      </DropdownItem>
                                    )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </TableData>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              )}

              <CardReportDigitationComponent
                toggleShowList={toggleShowList}
                listData={resultSet}
                pages={pages}
                handleList={handleList}
                setTagDataModal={setTagDataModal}
                getLinkFormalize={getLinkFormalize}
              />

              <PaginationSelector pages={pages.pages} list={handleList} />
            </>
          ) : (
            <>
              <Alert color="secondary">
                <h2>Não foram identificados registros para esta consulta</h2>
              </Alert>
            </>
          )}
        </Card>

        <ModalTagComponent
          tagDataModal={tagDataModal}
          setTagDataModal={setTagDataModal}
          serviceId={1}
        />
      </Container>
    </>
  );
};

export default ReportDigitationView;
