import Header from 'components/Headers/Header';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import AccessBankC6BankForm from './component/formBanks/accessBankC6BankForm';
import AccessBankFactaForm from './component/formBanks/accessBankFactaForm';
import AccessBankiCredForm from './component/formBanks/accessBankiCredForm';
import AccessBankQueroMaisForm from './component/formBanks/accessBankQueroMaisForm';
import AccessBankSafraForm from './component/formBanks/accessBankSafraForm';
import AccessBankMercantilForm from './component/formBanks/accessBankMercantilForm';
import { BankSelectData } from './service/bankSelectData';
import AccessBankPanForm from './component/formBanks/accessBankPanForm';
import { showAccessBank } from 'services/AccessBank/AccessBankService';
import { storeAccessBank } from 'services/AccessBank/AccessBankService';
import AccessBankBmgForm from './component/formBanks/accessBankBmgForm';
import AccessBankNovoSaqueForm from './component/formBanks/accessBankNovoSaqueForm';
import AccessBankCrefazForm from './component/formBanks/accessBankCrefazForm';
import AccessBankSabemiForm from './component/formBanks/accessBankSabemiForm';
import AccessBankBancoMasterForm from './component/formBanks/accessBankBancoMasterForm';
import AccessBankUppForm from './component/formBanks/accessBankUppForm';
import AccessBankUy3Form from './component/formBanks/accessBankUY3Form';
import AccessBankGRANDINOForm from './component/formBanks/accessBankGRANDINOForm';
import AccessBankLotusMaisForm from './component/formBanks/accessBankLotusMaisForm';
import AccessBankV8Form from './component/formBanks/accessBankV8Form';
import AccessBankPrataDigitalForm from './component/formBanks/accessBankPrataDigitalForm';
import AccessBankVCTexForm from './component/formBanks/accessBankVCTexForm';
import AccessBankPresencaBankForm from './component/formBanks/accessBankPresencaBankForm';
import AccessBankCrefisaForm from './component/formBanks/accessBankCrefisaForm';
import AccessBankFintechDoCorbanForm from './component/formBanks/accessBankFintechDoCorbanForm';

const AccessBankConfigure = () => {
  let { febrabanId } = useParams();
  const history = useHistory();
  const [data, setData] = useState({
    id: null,
    febrabanId: febrabanId,
    login: '',
    password: '',
    documentUser: null,
    idBankUser: null,
    promoterIntegration: null,
    tableAmmount: null,
    apiKey: null,
    token: null,
    ufUserDigitation: null,
    useSafe: 0,
    useFgts: 1,
    useConsigned: 1,
    useMultBankFgts: 1,
    status: 0,
    maxValueLibertyFgts: null,
    nameUser: 'Não informado',
  });

  const bank = BankSelectData(febrabanId);

  const handleGetAccessBank = (febrabanId) => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    showAccessBank(febrabanId)
      .then((res) => {
        Swal.close();
        setData(res.data.resultSet);
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: 'Carregando',
      text: 'Por favor, aguarde.',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    storeAccessBank({
      id: null,
      login: '',
      password: '',
      documentUser: null,
      idBankUser: null,
      promoterIntegration: null,
      tableAmmount: null,
      apiKey: null,
      token: null,
      ufUserDigitation: null,
      useSafe: 0,
      useFgts: 1,
      useConsigned: 1,
      useMultBankFgts: 1,
      status: 0,
      maxValueLibertyFgts: null,
      nameUser: 'Não informado',
      ...data,
      febrabanId: febrabanId,
    })
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Alteração realizada com sucesso!',
        });
      })
      .catch((err) => {
        const code = !err.response ? '00000' : err.response.data.code;
        const message = !err.response
          ? 'Ocorreu um erro inesperado'
          : err.response.data.message;
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `[${code}] ${message}`,
        });
      });
  };

  useEffect(() => {
    handleGetAccessBank(febrabanId);
  }, []);

  return (
    <>
      <Header showCards={false} />
      <Container className="mt--9" fluid>
        <Alert color="warning">
          <h3 className="text-white">
            Atenção: Propostas digitadas até a data da alteração de login e/ou
            dados de API, não terão seus status atualizados, devido à perda de
            vínculo com os dados anteriores.
          </h3>
        </Alert>
        <Card className="shadow">
          <CardHeader>
            <Row>
              <Col>
                <img src={bank.img} alt={bank.name} />
              </Col>
            </Row>
          </CardHeader>
          <AccessBankSafraForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankiCredForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankFactaForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankBancoMasterForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankC6BankForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankMercantilForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankPanForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankBmgForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankSabemiForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankQueroMaisForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankNovoSaqueForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankCrefazForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankUy3Form
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankGRANDINOForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankUppForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankLotusMaisForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankV8Form
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankPrataDigitalForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankVCTexForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankPresencaBankForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankCrefisaForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <AccessBankFintechDoCorbanForm
            febrabanId={febrabanId}
            data={data}
            setData={setData}
          />
          <CardFooter>
            <Button onClick={handleSubmit} color="success">
              <i className="fas fa-save" /> Salvar
            </Button>
            <Button
              color="warning"
              onClick={() => {
                history.push(`/admin/access-bank`);
              }}
            >
              <i className="fas fa-arrow-left" /> Voltar
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
};

export default AccessBankConfigure;
